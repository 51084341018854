.creativeIdeasContainer {
  background: rgb(255, 119, 3);
  background: linear-gradient(45deg,#3a3b3ccc,#3a3b3c 50%,#3a3b3cc7);
}

.MuiPickersPopper-root,.MuiDialog-root,.MuiPickersPopper-paper{
  z-index: 10001 !important;
}

.MuiClock-pin,.MuiClockPointer-root,.MuiClockPointer-thumb{
  background-color: #00BB07 !important;
}
.MuiClockPointer-thumb{
  border: 16px solid #00BB07 !important;
}
.MuiClock-root > .css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,.css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton{
  background-color: #00BB07 !important;
}

.css-3eghsz-PrivatePickersYear-button.Mui-selected{
  background-color: #00BB07 !important;
}

.MuiDayPicker-weekContainer > .Mui-selected{
  background-color: #00BB07 !important;
}

.creativeIdeasContainer a {
  text-decoration: none;
}

.creativeIdeasCaption {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-subheading);
  background-color: #ffffff;
  text-transform: capitalize;
  text-align: center;
}

.creativeIdeasCaptionWrapper > p {
  padding: 20px;
  min-height: 100px;
  display: flex;
  align-items: center;
}

.creativeIdeasBox {
  border: 4px solid white;
  padding: 20px;
}

.creativeIdeasImage {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.creativeIdeasHeading {
  color: #000000 !important;
}

.creativeIdeasBoxHeading{
  font-size: var(--desktop-text);
}

.creativeIdeasSubtext{
  font-size: var(--desktop-subtext);
}

.ideas-hover img:hover {
  height: 495px;
  width: 95%;
  cursor: pointer;
}
.ideas-hover p:hover {
  cursor: pointer;
}

.outerDivCreative_Idea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  flex-wrap: wrap;
}
.creativeIdeasDiv:hover {
  transform: scale(0.95);
  transition: 0.4s all ease-in-out;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 992px) {
  .creativeIdeasImage {
    height: 385px;
  }

  .creativeIdeasBox {
    border: none;
    padding: 0;
  }

  .shopBySlider_Main > .slick-arrow {
    display: none !important;
  }
  .creativeIdeasCaption {
    font-size: var(--tablet-subheading);
  }
}

@media (max-width: 768px) {
  .creativeIdeasImage {
    height: 280px;
  }
}

@media (max-width: 576px) {
  .creativeIdeasImage {
    height: 300px;
  }

  .creativeIdeasDiv {
    width: 90%;
  }

  .creativeIdeasCaptionWrapper > p {
    min-height: 70px;
  }

  .shopBySlider_Main > .slick-arrow {
    display: none !important;
  }
  .creativeIdeasCaption {
    font-size: var(--mobile-subheading);
  }
  .interiorTopMargin {
    margin-top: 0px !important;
  }
  .imgPostiion {
    margin-left: 0px !important;
  }
}

@media (min-width: 993px) and (max-width: 1150px) {
  .mediaScreen {
    height: 530px !important;
  }
}

@media (min-width: 993px) and (max-width: 1400px) {
  /* .creativeIdeasCaption {
    font-size: 20px !important;
  } */

  .creativeIdeasCaptionWrapper > p {
    min-height: 90px;
  }
}

@media (min-width: 1151px) and (max-width: 1300px) {
  .mediaScreen {
    height: 585px !important;
  }
}

@media (min-width: 1525px) {
  .mediaScreen {
    height: 640px !important;
  }
}

@media (min-width: 1625px) {
  .mediaScreen {
    height: 670px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 24px !important;
  } */
}

@media (min-width: 1725px) {
  .mediaScreen {
    height: 700px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 24px !important;
  } */
}
@media (min-width: 1825px) {
  .mediaScreen {
    height: 740px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 24px !important;
  } */
}
@media (min-width: 1925px) {
  .mediaScreen {
    height: 780px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 26px !important;
  } */
}

@media (min-width: 2100px) {
  .mediaScreen {
    height: 880px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 28px !important;
  } */
}

@media (min-width: 2400px) {
  .mediaScreen {
    height: 940px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 33px !important;
  } */
}

@media (min-width: 2800px) {
  .mediaScreen {
    height: 1020px !important;
  }
  /* .creativeIdeasCaption {
    font-size: 33px !important;
  } */
}
