.counterButton {
    width: 28px;
    border: 0.5px solid #D9D9D9;
    background-color: transparent;
    color: black;
}

.counterButtonDisable {
    width: 28px;
}

.counterButtonDisable:disabled,
.counterButtonDisable[disabled] {
    border: 0.5px solid #D9D9D9;
    background-color: transparent;
    color: black;
}

.productCounterCenter {}

.counterValue {
    margin: 10px;
}

.cartCounterValueInput {
    width: 40px;
    border-radius: 0px;
    border: 1px solid #575757;
    padding-left: 2px;
    padding-right: 2px;
}

.cartCounterValueInput:focus-visible {
    border: 1px solid black;
    outline: none;
}

.cartCounterValueInput::-webkit-outer-spin-button,
.cartCounterValueInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 576px){
    .cartCounterValueInput {
        width: 24px !important;
        /* border: yellow; */
    }
    .counterButtonDisable{
        width: 25px !important;
    }
    .counterButton{
        width: 25px !important;
    }
}