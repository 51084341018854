.renderside_profile_form {
  padding: 20px;
  margin-right: 30px;
}

.profile_form_content {
  padding: 10px !important;
  background: #ffffff;
  border: 0.5px solid #878787;
}

.mobileNumberEditAddress{
  white-space: nowrap;
}

.editAddressInputLabel{
  font-weight: 350;
}

.changePasswordButton{
  border: 1px solid #FF7703;
    background-color: #FF7703;
    color: #ffffff;
        padding: 0.25rem 0.5rem;
}

.profile_form_content {
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.image_scale:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.image_scale:hover>path {
  stroke: #fd7e14;
}

.deleteaddress:hover {
  cursor: pointer;
  transform: scale(1.3);
}

.deleteaddress:hover>path:last-child {
  fill: red;
}

/* .profile_form_change_btn:hover {
  transform: translateY(-2px);
  cursor: pointer;
  color: #fd7e14 !important;
} */

.inputFields_Outside {
  width: 100%;
}

.inputFields_Outside > input {
  color: #878787;
}

.profile_form_content_inside {
  font-family: "Poppins";
  font-style: normal;
  width: 100%;
  font-weight: 500;
  font-size: var(--desktop-text);
  color: #000000;
  padding: 5px;
  align-items: center;
  display: flex;
  background-color: #ffffff !important;
}

.profile_form_content_inside_data{
  width: 100%;
  color: #000000;
  padding: 5px;
  align-items: center;
  display: flex;
  background-color: #ffffff !important;
}

.UserProfileGender {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.RadioButtonMobile > label > span > span{
  transform: scale(0.7);
}

.RadioButtonMobile > label > span{
  font-size: 14px !important;
  margin-right: -5px !important;
}

.GenderRadio {
  margin-left: 100px;
}

.inputFields_Outside > .form-control {
  border: none !important;
  background-color: #ffffff !important;
}


.inputFields_Outside > .form-control:focus-visible {
  background-color: #ffffff !important;
}

.inputFields_Outside > .form-control:focus {
  background-color: #ffffff !important;
  border-color: #fff;
  box-shadow: none;
}

.profile_form_content_inside_edit {
  display: flex;
  flex-direction: column;
}

.UserProfileGender span{
  font-weight: 350;
}

.profile_form_content_inside_edit>.deleteaddress {
  height: 25px;
  width: 25px;
  margin-top: 5px;
  cursor: pointer;
}

.addUpdateButton:hover {
  /* background-color: #ffffff !important;
  box-shadow: 0px 10px 15px #faa458;
  color: #FF7703 !important;
  transform: translateY(-5px); */
}

.profile_form_content_inside_edit>.deleteaddress:last-child {
  fill: #878787;
}

.profile_form_content_inside_edit>img {
  cursor: pointer;
  height: 21px;
  width: 20px;
  margin-left: 4px;
}

.profile_form_change_btn {
  color: #8a5b40;
  text-decoration-line: underline;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin-top: 10px;
  transition: none;
  display: flex;
  flex-direction: column;
}

.profile_form_change_btn:hover,
.profile_form_change_btn:active,
.profile_form_change_btn:focus,
.profile_form_change_btn:active:focus {
  background: transparent;
  outline: none !important;
  border: none !important;
  color: #FF7253;
  box-shadow: none;
}

.password_change_popup {
  padding: 30px 50px;
  /* background: #e5e5e5; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.address_type>.type_selec {
  display: flex;
  align-items: center;
}

.address_type>.type_selec>button {
  background-color: #878787;
  border: 1px solid #878787;
  /* padding: 10px; */
  width: 120px;
  margin-right: 10px;
  height: 36px;
}

.type-selected {
  color: white;
  background-color: #8a5b40 !important;
}

.deleteAddressModalBody {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.deleteAddressModalBody>button {
  width: 120px;
  margin-right: 10px;
  height: 36px;
}

.deleteAddressModalBody > .addUpdateButton{
  background-color: #FF7703;
  border: 1px solid #FF7703;
  color: #ffffff;
}

.deleteAddressModalBody > .addUpdateButton:hover{
  /* background-color: #ffffff !important;
  box-shadow: 0px 5px 10px #faa458;
  color: #FF7703 !important;
  transform: translateY(-1px); */
}

.deleteAddressModalBody > .cancelAddUpdate{
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #FF7703;
}

.deleteAddressModalBody > .cancelAddUpdate:hover{
  /* background-color: #FF7703 !important;
  border: 1px solid #FF7703;
  box-shadow: 0px 5px 10px #faa458;
  color: #ffffff !important;
  transform: translateY(-1px); */
}

.deleteAddressModalBody>button:hover {
  /* background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #8a5b40; */
}

.modal-body {
  width: 100%;
}

.modal-body input,
textarea {
  background: transparent;
  outline: none;
  border: none;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #878787;
  padding-top: 7px;
}

.modal-body input::placeholder,
textarea::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

.modal-header {
  font-family: "Trirong";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-subheading);
  color: #000000;
  display: flex;
  justify-content: center;
}

.close {
  position: absolute;
  right: 20px;
  top: 5px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 30px;
  color: #000000;
  margin-bottom: 10px;
}

.modal-footer {
  margin-top: -20px;
  width: 100%;
}

.renderside_profile {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.renderproflestyle {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.renderproflestyle_coloumn {
  display: flex;
  align-items: center;
}

.renderproflestyle_coloumn>span {
  font-family: Poppins;
  font-size: var(--desktop-text);
  ;
  letter-spacing: 0em;
  text-align: left;
  font-family: Poppins;
  text-transform: capitalize;
}

.style_key {
  font-weight: 300;
}

.style_value {
  font-weight: 400;
  padding-left: 10px;
}

.renderside_action_buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0px auto;
  width: 90%;
}

.renderside_action_buttons>button {
  width: 360px;
  height: 45px;
  margin-bottom: 15px;
  font-family: Poppins;
  margin-left: 20px;
  font-size: var(--desktop-text);
  font-weight: 400;
  ;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
}

.profile_main_coloumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  flex: 0.7;
  margin-top: 20px;
  height: 75vh;
}

.edit__profile {
  border: 1px solid #FF7703;
  background-color: #FF7703;
  color: #ffffff;
  text-transform: capitalize;
  width: 100%;
  max-width: 100%;
}

.edit__profile:hover {
  /* background-color: #ffffff !important;
  border: 1px solid #ffffff;
  box-shadow: 0px 10px 15px #ce742b;
  color: #FF7703 !important;
  transform: translateY(-4px); */
}

.change__password {
  border: 1px solid #FF7703;
  background-color: #ffffff;
  color: black;
  text-transform: capitalize;
  width: 100%;
  max-width: 100%;
}

.change__password:hover {
  /* background-color: #FF7703 !important;
  border: 1px solid #FF7703;
  box-shadow: 0px 10px 15px #ce742b;
  color: #ffffff !important;
  transform: translateY(-4px); */
  color: black;
}

.MobileView{
  display: flex;
  flex-direction: column;
}
.headingView{
  padding: 15px;
  font-size: 22px;
  font-weight: 600;
}
.Actions_profile{
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.Actions_profile > svg{
  stroke-width: 20px !important;
}
.Actions_type{
  display: flex;
  flex-direction: column;
}
.Actions_type_one{
  font-size: 14px;
  font-weight: 400;
}
.Actions_type_two{
  font-size: 10px;
  color: #9D9D9D;
}
.actionsMobileItems{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  
  .renderproflestyle_coloumn>span{
    font-size: var(--tablet-text);
  }

  .change__password {
    width: 100%;
  }  
  .renderside_action_buttons{
    margin: 0px;
    width: 67vw;
  }
  .profile_handler{
    min-height: 100px;
  }
  .profile_handler > button {
    width: 65%;
    margin: 0px !important;
  }

}

@media screen and (max-width: 768px) {
  .renderside_profile_form {
    padding-right: 0px;
    margin-right: 0px;
  }

  .profile_form_content_inside {
    font-size: var(--tablet-text);
  }
  .profile_form_content_inside_data{
    font-size: var(--tablet-text);
    
  }
}

@media screen and (max-width: 576px) {

  .renderproflestyle_coloumn>span{
    font-size: var(--mobile-text);
  }

  .inputFields_Outside > .inputFields{
    font-size: var(--mobile-text);
  }

  .profile_form_content_inside,
  .profile_form_content_inside_data,
  .profile_form_content_inside_edit {
    font-size: var(--mobile-text);
  }

  .modal-header h5 {
    font-size: 18px;
    ;
  }

  .modal-footer button {
    font-size: 14px;
    
  }

  .renderside_profile_form {
    padding: 0px;
    margin-right: 0px;
  }
}