.swiper-3d .swiper-slide-shadow{
    background: none!important;
}

.cascade-slider_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.cascade-slider_slides{
    width: 70%;
}

.swiper.swiper-horizontal{
    width: 100%;
    max-width: 100%;
}

.turnKeyFormModal div div:first-child{
    z-index: 99999999999999999!important;
}

.turnKeyFormModal .close{
    position: absolute;
    right: 40px;
    top: 35px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 30px;
    color: #000000;
    margin-bottom: 10px;
    cursor: pointer;
}