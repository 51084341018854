.middle{
    /* margin-left: auto;
    margin-right: auto; */
    width: 100%;
    display: flex;
    justify-content: center;
}
.reset-password-form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.reset-password-form > h2 {
    font-weight: 600;
    font-size: var(--desktop-heading);
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
}

.reset-password-form > .form-box {
    display: flex;
    flex-direction: column;
}

.reset-password-form > .form-box > .MuiFormControl-root{
    width: 55ch;
    margin-bottom: 35px;
}

.reset-password-form-button{
    width: 50%;
    margin: 0px auto;
    padding: 10px;
    border: 1px solid #FF7703;
    background-color: #FF7703;
    color: #ffffff;
}

.reset-password-form-button:hover{
    color: #FF7703;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 3px 5px 7px #faa458;
    transform: translateY(-3px);
}

@media screen and (min-width: 120px) and (max-width:600px){
    .reset-password-form > .form-box > .MuiFormControl-root{
        width: 40ch;
        margin-bottom: 35px;
    }
}

@media screen and (max-width:576px){
    .reset-password-form > h2 {
        font-size: var(--mobile-heading);;
    }
}

@media screen and (max-width:992px){
    .reset-password-form > h2 {
        font-size: var(--tablet-heading);;
    }
}