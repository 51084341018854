.collectionParent{
    text-align: center;
    width: 60%;
    margin: auto;
}

.collectionParent .splide__list{
    align-items: center;
}

#collectionBtn {
    width: fit-content;
    border-radius: 85px;
    border: 1px solid #848484;
    background: #DBDBDB;
    transition: border 0.1s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.collectionParent .splide__track{
    width: calc(100% - 120px);
    margin: auto;
}

.collectionParent .splide__slide{
    width: fit-content!important;
    padding: 0 1rem;
}

/* .splideslide {
    margin-left: 20px;
    margin-right: 20px;
} */

.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    display: flex;
    background-clip: padding-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;
}

.my-masonry-grid_column:nth-child(2n){
    padding-top: 20%!important;
}

.my-masonry-grid_column>div {
    margin-bottom: 20px;
}

.masonry-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: auto;
    position: relative;
    overflow: hidden;
    max-height: 100vh;
}

.masonry-container .d-flex{
    transform: translateY(-20%);
}

.masonry-item {
    flex: 1;
    margin: 10px;
}

@media (max-width: 576px) {
    .collectionParent {
        margin: auto;
    }

    .splide__arrow svg {
        height: .8em;
        width: .8em;
    }

    .collectionParent, .masonry-container{
        width: 100%!important;
    }

    .collectionParent .splide__slide{
        width: fit-content!important;
        padding: 0 0.5rem;
    }

    .masonry-container{
        max-height: 75vh!important;
    }
}

@media (max-width: 992px) {
    .masonry-container{
        max-height: 125vh;
    }

    .collectionParent .splide__track{
        width: calc(100% - 120px);
        margin: auto;
    }

    .collectionParent, .masonry-container{
        width: 100%;
    }

    .my-masonry-grid_column {
        padding: 0 0.25rem;
    }

    .my-masonry-grid_column:nth-child(2n){
        padding-top: 25%!important;
    }

    .masonry-container .d-flex{
        transform: translateY(-11%);
    }
}