.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 57px;
    margin-top: 57px;
  }
  
  .paginationItem {
    background: #fff;
    border: 1px solid #666;
    padding: 10px 15px;
    /* border-radius: 50%; */
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
    color: black;
  }
  
  .paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .prev,
  .next {
    background: #fff;
    border: none;
    padding: 10px;
    color: blue;
    /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.4); */
    margin: 0 10px;
    cursor: pointer;
  }
  
  .paginationItem.active {
    border: 1px solid #8A5B40;
    color: #ffffff;
    background-color: #8A5B40;
    pointer-events: none;
  }
  
  .prev.disabled,
  .next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
  }

  .dataContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
  }
  .countData{
    font-weight: 400;
    font-size: var(--desktop-text);
    width: 279px;
    height: 30px;
    color: #878787;
    margin-top: -20px;
  }

@media screen and (max-width: 576px){
    .countData{
      font-size: var(--mobile-text);
    }
}

@media screen and (max-width: 992px){
  .countData{
    font-size: var(--tablet-text);
  }
}