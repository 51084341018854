.error_page_mainn img {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  opacity: .6;
}

.error_page_img {
  position: relative;
}

.error_page_inner_contentt {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.error_page_textt1 {
  font-family: "Poppins";
  font-style:error_page_text1 normal;
  font-weight: 700;
  font-size: 70px;
  color: #8a5b40;
}

.error_page_textt2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 240px;
  color: #ffffff;
}

.error_page_textt3 {
  color: #ffffff;
  font-family: "Poppins";
  font-size:40px;
  position: relative;
  text-align: center;
}



.error_page_button {
  margin-top: var(--desktop-text);
  color: #ffffff;
  border: 1px solid #8a5b40;
  background-color: #8a5b40;
  padding: 10px 50px;
  font-family: "Poppins";
  font-size: 20px;
  cursor: pointer;
}

.error_page_button:hover{
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  box-shadow: 0px 10px 15px rgba(216, 126, 7, 0.4);
  color: #8a5b40 !important;
  transform: translateY(-4px);
}

@media screen and (max-width: 1200px) {
  .error_page_textt1 {
    font-size: 100px;
  }

  .error_page_textt2 {
    font-size: 180px;
  }
}

@media screen and (max-width: 992px) {
  .error_page_textt1 {
    font-size: 50px;
    display: flex;
    text-align: center;
  }

  .error_page_textt2 {
    font-size: 100px;
  }
  .error_page_button {
    padding: 10px 40px;
    font-size: var(--tablet-text);
  }
  .error_page_textt3,
  .error_page_button {
    font-size: var(--tablet-text);
  }

  .error_page_button {
    padding: 5px 30px;
  }

  .error_page_mainn {
    padding: 0 !important;
    margin: 0 !important;
  }

  .error_page_textt3::before {
    top: 13px;
  }

  .error_page_textt3::after {
    top: 13px;
  }
}

@media screen and (max-width: 768px) {
  .error_page_textt1 {
    font-size: 30px;
    display: flex;
    text-align: center;
  }

  .error_page_textt2 {
    font-size: 60px;
  }

  .error_page_textt3,
  .error_page_button {
    font-size: var(--tablet-text);
  }

  .error_page_textt3::before {
    top: 10px;
    left: -40px;
  }

  .error_page_textt3::after {
    top: 10px;
    right: -40px;
  }
}

@media screen and (max-width: 576px) {
  .error_page_textt1 {
    font-size: 30px;
    display: flex;
    text-align: center;
  }

  .error_page_textt2 {
    font-size: 35px;
  }

  .error_page_textt3,
  .error_page_button {
    font-size: 12px;
  }

  .error_page_textt3::before {
    top: 8px;
    left: -30px;
  }

  .error_page_textt3::after {
    top: 8px;
    right: -30px;
  }
}
