.ourServicesImage {
    max-width: inherit;
    width: 100%;
}

.shopbyprice_product_details {
    font-family: Poppins;
    text-align: left;
    color: #878787;
}

.newHpOurServices .splide{
    padding: 0;
}

.newHpOurServices div{
    padding: 0;
}