.renderside_cart {
  padding: 20px 0;
  /* margin: 0 20px; */
}

.checkoutModalBody {
  display: flex;
  flex-direction: column;
}

.cartside_total_heading{
  margin-bottom: 30px;
}

.borderedContainer {
  border: 1px solid #878787;
  /* padding: 1em; */
  margin-bottom: 2em;
}

/* .paymentRadioInputButton:after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -3px;
  left: -3px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

.paymentRadioInputButton{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

/* .paymentRadioInputButton:checked + label:after{
  border: 1px solid #fd7e14 !important;
  color: #FF7703 !important;
  visibility: visible;
} */
.paymentRadioInputButton {
  display: none;
}
.paymentRadioInputButton + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.3rem;
  height: 1.3rem;
  margin-bottom: 0.3rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}
.paymentRadioInputButton:checked + * {
  color: #FF7703;
}
.paymentRadioInputButton:checked + *::before {
  background: radial-gradient(#FF7703 0%, #FF7703 40%, transparent 50%, transparent);
  border-color: #FF7703;
}

/* basic layout */
fieldset {
  margin: 20px;
  max-width: 400px;
}
.paymentRadioInputButton + * {
  display: inline-block;
  padding: 0.5rem 1rem;
}

/* .paymentRadioInputButton:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -3px;
  left: -3px;
  position: relative;
  background-color: #FF7703;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

.cartImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cartDetail {
  width: -webkit-fill-available;
}

.cartInline {
  display: inline;
}

.orderButtons {
  display: flex;
  width: 100%;
  justify-content: start;
}

.checkoutModalBody>.orderButtons>button {
  /* background-color: #FF7703;
  border: 1px solid #8a5b40;
  color: #ffffff;
  width: 120px;
  margin: 0px auto;
  height: 36px; */
}

.checkoutModalBody>.profile_form_content:hover {
  cursor: pointer;
  background-color: #FF7703;
  color: #ffffff;
}

.checkoutModalBody>.profile_form_content>.profile_form_content_inside:hover {
  color: #ffffff;
}

.cart_display_info {
  display: flex;
}

.renderside_cart {
  height: 160px;
  border-bottom: 1px solid #878787;
}

.thankYouLargeContainer {
  height: 100vh;
  width: 100%;
  background: #0000005b;
  display: grid;
  place-items: center;
  position: fixed;
  z-index: 5;
  max-width: 100vw;
}

.thankYouContainer {
  width: 50%;
  height: 50%;
  background: #E5E5E5;
  border-radius: 3px;
}

.thankYouImage {
  max-width: 100%;
}

.thankYouButton {
  width: 300px;
  background: #FF7703;
  color: white;
  border: 0;
}

.cart_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
}

.cart_info_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

.cart_info_company {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: #878787;
}

.cart_info_quantity {
  display: flex;
}

.cart_info_quantity_number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

.cart_info_quantity_remove {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  text-decoration-line: underline;
  color: #8a5b40;
  margin-left: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.cart_info_quantity_remove:hover {
  /* transform: translateY(-2px); */
  color: #fd7e14;
}

.cart_info_price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

.renderside_cartside_total {
  background: #ebebeb00;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border: 0.5px solid #878787;
  width: inherit;
}

.cartside_total_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: var(--desktop-subheading);
  color: #000000;
}

.cartside_total_total,
.cartside_total_discount,
.cartside_total_tax,
.cartside_total_subtotal {
  display: flex;
  justify-content: space-between;
}

.cartside_total_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

.cartside_total_amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: #000000;
}

.cartside_total_subtotal {
  margin-top: 10px;
  border-top: 1px solid black;
  /* border-bottom: 1px solid black; */
  padding: 10px 0;
}

.cartside_total_subtotal .cartside_total_text,
.cartside_total_subtotal .cartside_total_amount {
  font-size: 20px;
  ;
}

.changeAddressButton {
  background-color: transparent;
  border: 1px solid #FF7703;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-family: "Poppins";
  font-size: var(--desktop-text);
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cartside_total_checkout {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 4px 10px;
  cursor: pointer;
  color: #ffffff;
  background: #FF7703;
  margin-top: 20px;
  width: 100%;
  border: none;
}

.cart_img {
  height: 100px !important;
  width: 100px !important;
}

.cart_img>img {
  height: 100% !important;
  width: 100% !important;
}

@media screen and (max-width: 992px) {
  .renderside_cartside_img {
    display: block;
    width: 300px;
    margin-top: 0px;
  }

  .changeAddressButton{
    font-size: var(--tablet-text);
  }

  .renderside_cartside_img_bottom {
    display: none;
  }

  .profile_renderside_cart {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .cart_info_quantity {
    flex-direction: column;
  }

  .changeAddressButton{
    font-size: var(--tablet-text);
  }

  .thankYouContainer {
    width: 75%;
  }

  .cart_info_quantity_remove {
    margin-left: 0;
  }

  .renderside_cart {
    margin-right: 0;
    position: relative;
  }

  .cart_info_price {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .cart_info_heading,
  .cartside_total_heading
  {
    font-size: var(--tablet-subheading);
  }

  .cart_info_company,
  .cart_info_quantity_number,
  .cart_info_quantity_remove,
  .cart_info_price,
  .cartside_total_text,
  .cartside_total_amount {
    font-size: var(--tablet-text);
  }

  .cartside_total_heading {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .thankYouContainer {
    width: 85%;
  }

    .paymentRadioInputButton + *::before {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.1rem;
      margin-right: 0.3rem;
  }

  .paymentOptionOption{
    font-size: var(--mobile-text) !important;
  }

  .changeAddressButton{
    font-size: var(--mobile-text);
    padding: 6px;
    margin-top: -15px;
  }

  .renderside_cart {
    margin-left: 0;
  }

  .cart_info_heading {
    font-size: var(--mobile-text);
    ;
  }

  .cart_info_company,
  .cart_info_quantity_number,
  .cart_info_quantity_remove,
  .cart_info_price {
    font-size: var(--mobile-subtext);
    
  }

  .cart_img img {
    height: 100%;
    width: 100%;
  }

  .cart_info_price {
    position: absolute;
    bottom: 40px;
    right: 0;
  }

  .renderside_cart {
    height: 140px;
  }

  .choosePaymentOption {
    flex-direction: column;
  }

  .profile_renderside_cart {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    border-top: 5px rgba(0, 0, 0, 0.05) !important;
    margin-top: 10px;
  }

  .renderside_cartside_total {
    border: 0;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .paymentOption {
    border-top: 0 !important;
    border-left: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-right: 0 !important;
    width: 100% !important;
  }

  .choosePaymentOptionHeading {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: var(--mobile-heading) !important;
    ;
  }

  .paymentOptionOption {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--mobile-text);
    ;
  }

  .cartside_total_heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400 !important;
    font-size: var(--mobile-subheading) !important;
    color: #878787 !important;
  }

  .renderside_cartside_img {
    width: 100%;
  }

  .cartside_total_subtotal {
    font-weight: bold;
  }
}

.cartside_total_heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--desktop-subheading);
  
  color: #000000;
}

.paymentOptionOption {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.choosePaymentOptionHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  
}

.paymentOption {
  border-top: 1px solid gray;
  border-bottom: none;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  width: 100%;
  cursor: pointer;
}

.paymentOption:last-child{
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  width: 100%;
}

.paymentOption input:checked~label {
  color: black
}