.renderside_cart {
  padding: 20px 0;
  /* margin: 0 20px; */
}
.checkoutModalBody{
  display: flex;
  flex-direction: column;
}

.borderedContainer{
  border: 1px solid #878787;
  /* padding: 1em; */
  margin-bottom: 2em;
}

.cartImage{
  width: 100px;
  height: 100px;
  object-fit: cover;
}


.cartInline{
  display: inline;
}

.orderButtons{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.checkoutModalBody > .profile_form_content:hover{
  cursor: pointer;
  background-color: #FF7703;
  color: #ffffff;
}
.checkoutModalBody > .profile_form_content >.profile_form_content_inside:hover{
  color: #ffffff;
}

.cart_display_info {
  display: flex;
}

.renderside_cart {
  height: 160px;
  border-bottom: 1px solid #878787;
}

.cart_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
}

.cart_info_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

.cart_info_company {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: #878787;
}

.cart_info_quantity {
  display: flex;
}

.cart_info_quantity_number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

.cart_info_quantity_remove {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  text-decoration-line: underline;
  color: #8a5b40;
  margin-left: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
}
.cart_info_quantity_remove:hover{
  /* transform: translateY(-2px); */
  color: #fd7e14;
}

.cart_info_price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

/* .renderside_cartside_total {
  background: #ebebeb00;
  padding: 25px;
  margin-bottom: 20px;
  border: 0.5px solid #878787;
  width: inherit;
} */

.cartside_total_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: var(--desktop-subheading);
  color: #000000;
}

.cartside_total_total,
.cartside_total_discount,
.cartside_total_tax,
.cartside_total_subtotal {
  display: flex;
  justify-content: space-between;
}
.cartside_total_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

.cartside_total_amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: #000000;
}

.cartside_total_subtotal {
  margin-top: 10px;
  border-top: 1px solid black;
  /* border-bottom: 1px solid black; */
  padding: 10px 0;
}

.cartside_total_subtotal .cartside_total_text,
.cartside_total_subtotal .cartside_total_amount {
  font-size: 20px;
  ;
}

.changeAddressButton{
  background-color: transparent;
  border: 1px solid #FF7703;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.cartside_total_checkout {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  ;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 4px 10px;
  cursor: pointer;
  color: #ffffff;
  background: #FF7703;
  margin-top: 20px;
}
.cart_img {
  height: 100px !important;
  width: 100px !important;
}
.cart_img > img{
  height: 100% !important;
  width: 100% !important;
}

@media screen and (max-width: 992px) {
  .renderside_cartside_img {
    display: block;
    width: 300px;
    margin-top: 50px;
  }

  .renderside_cartside_img_bottom {
    display: none;
  }

  .profile_renderside_cart {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .cart_info_quantity {
    flex-direction: column;
  }

  .cart_info_quantity_remove {
    margin-left: 0;
  }

  .renderside_cart {
    margin-right: 0;
    position: relative;
  }

  .cart_info_price {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .cart_info_heading,
  .cartside_total_heading{
    font-size: var(--mobile-subheading);
  }

  .cart_info_company,
  .cart_info_quantity_number,
  .cart_info_quantity_remove,
  .cart_info_price,
  .cartside_total_text,
  .cartside_total_amount {
    font-size: var(--tablet-text);
    ;
  }

  .cartside_total_heading {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .renderside_cart {
    margin-left: 0;
  }

  .cart_info_heading {
    font-size: var(--mobile-subheading);
    ;
  }

  /* .scrollHeight{
    height: 42vh !important;
    overflow-x: hidden !important;
  } */

  .cart_info_company,
  .cart_info_quantity_number,
  .cart_info_quantity_remove,
  .cart_info_price {
    font-size: var(--mobile-text);
    
  }

  .cart_img img {
    height: 100%;
    width: 100%;
  }

  .cart_info_price {
    position: absolute;
    bottom: 40px;
    right: 0;
  }

  .renderside_cart {
    height: 140px;
  }
}
