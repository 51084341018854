@import url("https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css");

.phone-container {
    position: fixed;
    top: 70vh;
    right: -160px;
    width: 230px;
    transition: all 0.35s ease-in-out;
    background-color: white;
    z-index: 500;
    cursor: pointer;
    height: 70px;
}

.active-phone-container {
    right: 0 !important;
}

.phone-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone-detail {
    width: 160px;
    height: 100px;
    flex: 1;
    text-decoration: none;
}

.phone-img {
    width: 70px;
    height: 70px;
}

.compBottomMargin{
    margin-bottom: 5em;
}

.compBottomMarginDesktop{
    margin-bottom: 5em;
}

@media screen and (max-width: 992px) {
    .compBottomMarginDesktop{
        margin-bottom: none;
    }
    .compBottomMarginTablet{
        margin-bottom: 5em!important;
    }
}

@media screen and (max-width: 576px) {

    .compBottomMarginTablet{
        margin-bottom: none;
    }

    .compBottomMargin{
        margin-bottom: 2em!important;
    }

    .compBottomMarginMobile{
        margin-bottom: 2em!important;
    }

    .phone-container {
        position: fixed;
        top: 80vh;
        right: -150px;
        width: 250px;
        transition: all 0.35s ease-in-out;
        background-color: white;
        z-index: 500;
        transform: scale(0.8);
        cursor: pointer;
    }

    .active-phone-container {
        right: 0 !important;
    }

    .phone-icon {
        width: 80px;
        height: 80px;
        max-height: 100%!important;
    }

    .phone-detail {
        width: 170px;
        height: 80px;
        flex: 1;
    }

    .phone-img {
        width: 80px;
        height: 80px;
        max-height: 100%!important;
    }
}