@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
.login-box {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    height: 100%;
    width: 100%;
    padding: 40px;
    /* transform: translate(-50%, -50%); */
    background: #fff;
    box-sizing: border-box;
    font-family: 'Poppins', serif;
    /* box-shadow: 0 15px 25px rgba(0,0,0,.6); */
    /* border-radius: 10px; */
  }
  .visitButton{
    margin-bottom: -100px;
  }
  .login-box h2 {
    /* margin: 0 0 30px; */
    padding: 0;
    color: black;
    text-align: center;
    font-family: 'Poppins', serif;
  }
  .login-box .user-box {
    position: relative;
    font-family: 'Poppins', serif;
  }
  
  
  .login-box .user-box input ,
  .login-box .user-box textarea {
    width: 100%;
    padding: 10px 0;
    font-size: var(--desktop-text);
    color: black;
    margin-bottom: 30px;
    font-family: 'Poppins', serif;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    background: transparent;
  }
  .login-box .user-box label {
    position: absolute;
    top:0;
    left: 0;
    padding: 10px 0;
    font-size: var(--desktop-text);
    color: #878787;
    font-family: 'Poppins', serif;
    /* pointer-events: none; */
    transition: .5s;
  }
  
  .login-box .user-box input:focus ~ label,
  .login-box .user-box textarea:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #878787;
    font-family: 'Poppins', serif;
    font-size: var(--desktop-subtext);
  }
  .visitContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background:#EBEBEB;
    padding:2rem;
    z-index:1000;
  }
  .visitContainer .feature{
    width: 200px;
    /* margin:auto; */
   }
  @media only screen and (max-width: 991px) {
   .visitContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
   .visitContainer .feature{
    width: 90%;
    margin:auto;
   }
   .verti{
    display: none;
   }
   .visitButton{
    margin-bottom: 0px;
   }
   .visitHeading{
    color: #fff;
   }
   .login-box .user-box input ,
   .login-box .user-box textarea {
      font-size: var(--tablet-text);
    }

    .login-box .user-box label{
      font-size: var(--tablet-text);
    }
    .login-box .user-box input:focus ~ label,
    .login-box .user-box textarea:focus ~ label,
    .login-box .user-box input:valid ~ label {
      font-size: var(--tablet-subtext);
    }
  }