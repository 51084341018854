#invoiceHtml{
    user-select: none;
}

.download_warning{
    margin: 10px;
    color: rgb(220, 53, 69);
    display: flex;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
}