.recentlyViewedItemsWrapper .slick-slide {
    width: fit-content !important;
}

.newRvi .splide__slide {
    height: auto !important;
}

.shopbyprice_product_image {
    aspect-ratio: 1;
    position: relative;
    background-color: #bfbfbf;
    border-radius: 20px;
}

.newRvi .sliderDiv{
    width: 100%;
    max-width: 100%;
}

.newRvi .rviProductNameTag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #414141;
    color: white;
    border-radius: 0 20px 20px 0;
    position: absolute;
    bottom: 20%;
    padding: 0.25em 1em;
    max-width: 90%;
    text-transform: lowercase;
}

.shopbyprice_rvproduct_name {
    font-size: var(--desktop-text);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    color: #8a5b40;
    margin-top: 5px;
}

.newRvi .recentlyViewedItemsImage {
    object-fit: cover !important;
    max-width: none!important;
    min-width: none!important;
    width: 100%!important;
    max-width: 100%!important;
    aspect-ratio: 1;
    padding: 0;
    border-radius: 20px;
}

@media screen and (max-width: 992px) {
    .shopbyprice_rvproduct_name {
        font-size: var(--tablet-text);
    }
}

@media screen and (max-width: 576px) {
    .shopbyprice_rvproduct_name {
        font-size: var(--mobile-text);
    }

    .newRvi .recentlyViewedItemsImage {
        min-width: 100% !important;
    }
}