.shopbyprice_main {
  display: flex;
  flex-direction: column;
}

.shopbyprice_image_row {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  /*TODO: add gaps when scroll bar enabled*/
  justify-content: space-evenly;
  /* align-items: center;
  flex-wrap: wrap;
  width: 100%; */
  /* width: 100%; */
  padding-bottom: 30px;
  margin-left: 30px;
}
.paddingImage{
  padding: 10px;
}
@media screen and (max-width: 1400px) {
  .shopbyprice_image_row{
    margin-left: 0px;
  }
}
.shopbyprice_product {
  margin-top: 10px;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.shopBySlider:link { text-decoration: none; }
.shopBySlider:visited { text-decoration: none; }
.shopBySlider:hover { text-decoration: none; }
.shopBySlider:active { text-decoration: none; }

.shopbyprice_product_image:hover{
  transform: scale(0.95);
  transition: 0.4s all ease-in-out;
  object-fit: cover;
  cursor: pointer;
}

.shopbyprice_product_name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-subheading);
  color: #8A5B40;
  margin-top: 5px;
}

.shopbyprice_product_price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: #464646;
  margin-top: -7px;
}

.shop_show_more_button {
  margin-top: 30px;
  display: flex;
  align-self: center;
}

.showmore_button {
  border: 1px solid #8a5b40;
  box-sizing: border-box;
  padding: 9px 31px;
  font-family: "Trirong";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-subheading);
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
  color: #8a5b40;
}

.showmore_button:hover {
  color: #fff;
  background: #8a5b40;
  cursor: pointer;
}

.shopbyprice_first_heading::before {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 50%;
  height: 1px;
  top: 15px;
  left: 130px;
}

.shopbyprice_first_heading::after {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 50%;
  height: 1px;
  top: 15px;
  right: 130px;
}

@media screen and (max-width: 1400px) {
  .shopbyprice_image_row {
    justify-content: left;
  }
}
@media screen and (max-width: 1050px) {
  .shopbyprice_product_name, .shopbyprice_brand_name, .shopbyprice_product_price{
    padding-left: 10px;
  }

  .ourServicesImage + .shopbyprice_product_name{
    padding-left: 0!important;
  }
}

@media screen and (max-width: 950px) {
  .shopbyprice_first_heading::before {
    top: 12px;
    left: 100px;
  }

  .shopbyprice_first_heading::after {
    top: 12px;
    right: 100px;
  }

  .shopbyprice_product_image img,
  .shopbyprice_product_image {
    /* height: 300px;
    width: 210px; */
  }

  .shopbyprice_product_name {
    font-size: var(--tablet-subheading);
    ;
  }

  .shopbyprice_product_image > img {
    width: 100% !important;
    padding: 10px;
  }

  .shopbyprice_product_price,
  .showmore_button {
    font-size: var(--tablet-text);
    ;
  }

  .showmore_button {
    margin-top: -20px;
  }

  .shopbyprice_image_row {
    padding-bottom: 15px;
  }

  .shopbyprice_main {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 576px) {
  .shopbyprice_first_heading::before {
    top: 10px;
    left: 80px;
  }

  .shopbyprice_first_heading::after {
    top: 10px;
    right: 80px;
  }

  .shopbyprice_product_image img,
  .shopbyprice_product_image {
    /* height: 270px; */
    /* width: 180px; */
  }

  .shopbyprice_product_image > img {
    width: 100% !important;
    /* padding: 10px; */
  }

  .shopbyprice_product_name {
    font-size: var(--mobile-subheading);
  }

  .shopbyprice_product_price,
  .showmore_button {
    font-size: var(--mobile-text);
    
  }
}
