.lowerNavigation{
    text-decoration: none;
    color: #777777;
    font-weight: 500; 
}

.lowerNavigation:hover{
    text-decoration: underline;
    color: #777777;
    font-weight: 500;
}

.lowerNavigationHeading:hover{
    text-decoration: underline;
    font-weight: bold;
}

.lowerNavigationLeftFlexParent{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 592px;
}

.lowerNavigationImg{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50vw;
    background: url("./img/img.png") no-repeat center center/cover;
}

.lowerNavigationLeft{
    /* padding-left: 5vw!important; */
}

@media screen and (max-width: 992px) {
    .lowerNavigationLeftFlexParent{
        display: flex;
        flex-direction: column;
        height: 585px;
    }

    .lowerNavigationLeft {
        /* padding-left: 18vw!important; */
    }
}