.sign_in_forget_password {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  /* identical to box height */
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #FF7253;
  margin-top: -15px;
  margin-bottom: 20px;
}
.form_register_button:hover{
  /* background-color: #ffffff !important; */
  /* box-shadow: 0px 10px 15px rgba(97, 56, 4, 0.4); */
  color: white!important;
  /* transform: translateY(-2px); */
}

.form_register_button:focus{
  box-shadow: none;
}
/* .form_login_button:hover{
  background-color: transparent !important;
  box-shadow: 0px 10px 15px rgba(97, 56, 4, 0.4);
  color: #ffffff !important;
  transform: translateY(-2px);
} */
.signin_modal_title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal_password_reset {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .signin_container::before {
    background-image: url("./images/sign_in.png");
  }
  .sign_in_forget_password{
    font-size: var(--tablet-text);
  }
}

@media screen and (max-width: 576px) {
  .modal_password_reset {
    font-size: 14px; 
  }
  .sign_in_forget_password{
    font-size: var(--mobile-text);
  }
}
