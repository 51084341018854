.newProductList {
  padding: 0;
  max-width: 100%;
}

.newProductList .relevance>select>option {
  background-color: #e5e5e5;
}

.MuiSlider-mark {
  color: #ff7003;
}

.newProductList .relevance {
  width: 15% !important;
  z-index: 1;
}

.newProductImage>img {
  cursor: pointer;
}

.newProductFavourite {
  cursor: pointer;
}

.tablet_mobile_heart{
  display: none;
}

.colorCol {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #FD8282;
}

.clearFilterButton{
  width: 36px;
}

.color_color {
  width: 30px;
  margin: 12px;
  display: grid;
  place-items: center;
  height: 30px;
  border-radius: 50%;
}

.color_circle {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  /* border: 1px solid black; */
  /* background-color: black; */
  /* margin-right: 20px;
  margin-bottom: 10px; */
}

.circle_color:nth-child(2) {
  background-color: red;
}

.color_container {
  display: flex;
  /* justify-content: space-around; */
  /* align-items: center; */
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.newProductList .relevance>.dropdown>button {
  background-color: #ffffff;
  color: #000000;
  border: none;
}

.BrandTable > tr > a:link { text-decoration: none; }
.BrandTable > tr > a:visited { text-decoration: none; }
.BrandTable > tr > a:hover { text-decoration: none; }
.BrandTable > tr > a:active { text-decoration: none; }

.BrandTable > div {
  text-transform: capitalize;
  color: #878787;
}
.BrandTable > div:hover{
  color: #FF7253;
  cursor: pointer;
}
.BrandTable > div > .selected_effect{
  color: #FF7253;
}
.BrandTable:hover{
  color: #FF7253;
}

.newProductList .relevance>.dropdown>button:focus-visible {
  background-color: #ffffff !important;
  border: none !important;
}

.newProductList .sortFilterDiv {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
}

.newProductList .newUpperDiv {
  display: flex;
  flex-direction: row;
}

.sortFilterDiv .insideDiv1 {
  width: 20%;
}

.sortFilterDiv .insideDiv2 {
  width: 80%;
}

.sortFilterDiv .filterDiv {
  margin: 20px 10px;
}

.sortBySpan {
  margin-left: 25px;
  color: black;
}

.mainProductDiv .insideDiv1 {
  border-top: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  height: min-content;
}

.newProductPrice span:nth-child(1) {
  font-size: 20px;
}

.newProductPrice span:nth-child(2) {
  font-size: var(--desktop-subtext);
}

.newProductPrice span:nth-child(3) {
  font-size: var(--desktop-text);
  background: transparent;
  color: #449a00;
}

.nplSortSelector{
  padding-left: 0.5rem;
  background-color: transparent;
  outline: none;
  font-family: Poppins;
  font-size: var(--desktop-text);
  font-weight: 300;
  ;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  color: black;
}

.nplSortSelector:focus-visible{
  background: transparent;
  outline: none;
  border: none;
}

.newProductFavourite > .selectedHeart {
  fill: #FF7703 !important;
}

.newProductFavourite > .selectedHeart > path{
  stroke :#FF7703 !important;
}

.tablet_mobile_heart > .selectedHeart {
  fill: #FF7703 !important;
}

.tablet_mobile_heart > .selectedHeart > path{
  stroke :#FF7703 !important;
}

.newProductList .newProductContainer {
  width: 25%;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.newProductList .newProductContainer:nth-child(n + 5) {
  margin-top: 42px;
}

.newProductList .newProductContainer img {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
}

.newProductList .newProductName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: black;
  margin-top: 8px;
}

.renderComp2Link {
  margin: 0;
  padding: 0;
  color: inherit;
  text-decoration: none;
}

.renderComp2Link:hover {
  color: inherit;
}

.renderComp2NameLink {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newProductList .newProductBrandName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  ;
  color: #878787;
  text-transform: uppercase;
}

.newProductList .newProductRating {
  margin-top: 7px;
}

.newMobileViewOptions {
  display: none;
}

.newProductList .price {}

.newProductList .reducedprice {}

.newProductList .discount {}

.noProductsFound {
  margin-left: auto;
  margin-right: auto;
}

.errorLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  max-height: 65vh;
}

.errorLoadingPrompt {
  height: fit-content;
  margin-left: 25px;
}

.DrawerSortBy{
  padding: 5px;
}

.DrawerSortBy_Detail{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
}

.DrawerSortBy_Detail:hover{
  background-color: #D9D9D9;
}

.DrawerSortBy_Detail > svg {
  height: 25px;
  width: 25px;
  stroke: #878787;
}
.DrawerSortBy_Detail > svg > path{
  fill: #D9D9D9 ;
}

.filterSlider > span{
  font-weight: 500 !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
}

.DrawerVariant > div:first-child{
  border-right: none !important;
}

.DrawerVariant > div > div > ul{
  padding-top: 0px !important;
}

.FilterShadowss{
  -webkit-box-shadow: 0px -6px 5px 0px rgba(166,164,166,1);
  -moz-box-shadow: 0px -6px 5px 0px rgba(166,164,166,1);
  box-shadow: 0px -6px 5px 0px rgba(166,164,166,1);
}

.filter_item{
  background-color: #E5E5E5;
  border-bottom: 0.5px solid #878787;
}

.filter_item_active{
  background-color: #ffffff;
  border-bottom: 0.5px solid #878787;
  border-top: 0.5px solid #ffffff;
}

.DrawerName > .MuiPaper-root{
  overflow-x: hidden !important;
}

.DrawerSortBy_Detail:hover > svg{
  stroke: #FF7703;
}

.DrawerSortBy_Detail > .activeStateClass{
  stroke: #FF7703; 
}

.DrawerSortBy_Detail > .activeStateClass > path{
  fill: #FF7703;
} 

.DrawerSortBy_Detail:hover > svg > path{
  fill: #FF7703;
}


@media (max-width: 1400px) {
  .newProductList .newProductContainer img {}
}

@media (max-width: 1200px) {
  .newProductList .newProductContainer img {}
}

@media (max-width: 992px) {
  .newProductList .newProductContainer img {
    height: 272px;
  }

  .sortFilterDiv .insideDiv1 {
    width: 25%;
  }

  .sortBySpan {
    margin-left: 20px;
  }

  .mainProductDiv .insideDiv1,
  .sortFilterDiv .insideDiv1 {
    /* max-width: 246px; */
    min-width: 25%;
  }

  .newProductList .newProductContainer {
    width: 50%;
    flex: 0 0 auto;
  }

  .newProductList .newProductContainer:nth-child(2n + 1) {
    margin-top: 40px;
    padding-right: 15px;
  }
  
  .newProductList .newProductContainer:nth-child(2n) {
    margin-top: 40px;
    padding-left: 15px;
  }

  .newProductList .newProductContainer:nth-child(1) {
    margin-top: 10px!important;
  }

  .newProductList .newProductContainer:nth-child(2) {
    margin-top: 10px!important;
  }

  .color_container {
    justify-content: left;
  }


  /* .newProductList .newProductContainer:nth-child(n + 4) {
    margin-top: 40px;
  } */

  .newProductList .newProductName {
    margin-top: 1px;
  }

  .newProductList .sortFilterDiv {
    padding-right: 0;
  }
}

@media (max-width: 576px) {
  .newProductList .newProductContainer img {
    height: 275px;
  }

  .noProductsFound {
    padding-bottom: 30vh;
  }

  .errorLoadingDiv {
    margin: auto;
    padding-bottom: 30vh;
  }

  .newProductList .newProductBrandName {
    font-size: 13px;
    
  }

  .newProductPrice span:nth-child(1) {
    font-size: 13px;
    
  }

  .newProductPrice span:nth-child(2) {
    font-size: 10px;
    
  }

  .newProductPrice span:nth-child(3) {
    font-size: 13px;
    
    background: transparent;
    color: #449a00;
  }

  .newProductList .newProductContainer {
    width: 50%;
    flex: 0 0 auto;
  }

  .newProductList .newProductName {
    font-size: 13px;
    
  }

  .newMobileViewOptions {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-left: 15px;
    margin-right: 15px;
  }

  .newMobileViewOptions>button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: var(--desktop-text);
    color: #000000;
    
    width: 50%;
    padding: 10px;
    border-radius: 0px;
    border: 0.5px solid #d9d9d9;
  }

  .newProductList .sortFilterDiv {
    padding-right: 0;
    padding-left: 0;
  }

  .sortFilterDiv .insideDiv2 {
    width: 100%;
  }

  .mainProductDiv .insideDiv1 {
    display: none !important;
  }

  .newProductList .newUpperDiv {
    display: none !important;
  }

  .newProductList .newProductContainer:nth-child(2n + 1) {
    padding-left: 15px !important;
    padding-right: 7.5px !important;
  }

  .newProductList .newProductContainer:nth-child(2n + 2) {
    padding-right: 15px !important;
    padding-left: 7.5px !important;
  }

  .newProductList .newProductContainer:nth-child(3n + 1) {
    padding-left: 0;
    padding-right: 0;
  }

  .newProductList .newProductContainer:nth-child(3n + 2) {
    padding-left: 0;
    padding-right: 0;
  }

  .newProductList .newProductContainer:nth-child(3n + 3) {
    padding-left: 0;
    padding-right: 0;
  }

  .newProductList .newProductContainer:nth-child(n + 4) {
    margin-top: 0;
  }

  .newProductList .newProductContainer:nth-child(n + 3) {
    margin-top: 18.34px !important;
  }

  .newProductList .newProductContainer:first-child,
  .newProductList .newProductContainer:nth-child(2) {
    margin-top: 12px;
  }

  .newProductFavourite {
    display: none;
  }

  .newProductContainer {
    position: relative;
  }

  .newProductList .newProductName {
    margin-right: 0;
  }
}