.EditButtons {
    margin-top: 5px;
}

.EditButtons>button {
    background-color: #8a5b40;
    border: 1px solid #8a5b40;
    color: #ffffff;
    /* font-size: 12px; */
    margin-right: 10px;
    /* padding: s4px; */
    height: 35px;
    width: 80px;
}

.edit_buttons:hover {
    background-color: #ffffff !important;
    box-shadow: 0px 10px 15px rgba(97, 56, 4, 0.4);
    color: #8a5b40 !important;
}