@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

:root {
    /*===== Colors =====*/
    --first-color: #8a5b40;
    --grey-color: #878787;
    --bootstrap-sm: 576px;
    --bootstrap-md: 768px;
    --bootstrap-lg: 992px;
    --bootstrap-xl: 1200px;
}

.borderColor_orange>path {
    stroke: #ff7253;
}

.mobileFavourite{
    z-index: 2;
}

.tabletFavourite>svg:hover>path {
    stroke: #ff7253;
}

.tabletFavourite>svg:hover {
    cursor: pointer;
}

body {
    font-family: "Poppins", sans-serif;
}

.biggerimg {
    margin-bottom: 15px;
}

.heart-red {
    color: "red" !important;
}

.biggerimg img {
    object-fit: contain;
    max-width: 100%;
    width: 60%;
    height: 300px;
}

.newProductReadMore{
    cursor: pointer;
    color: #8A5B40;
}

.loadingDetaildiv {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newProductSeeMore{
    color: #FF7703;
    cursor: pointer;
}

.newProductSpecificationDiv *{
    color: #8A5B40;
}

/* .smallerimg > img {
  margin-right: 13px;
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
} */

.mobileVariationButtonContainer {
    overflow: auto;
    display: flex;
}

.activeVariationButton {
    color: white;
    background: #ff7703 !important;
}

.mobileVariationButton {
    border: 1px solid #ff7703;
    background: transparent;
    padding: 2.5px 5px;
    margin-right: 10px;
}

.newProductStarRatingRow{
    margin-bottom: 15px;
}

.newProductTitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: var(--desktop-heading);
    margin-bottom: 0;
}

.newProductSubtitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: var(--desktop-subheading);
    
    color: #878787;
    text-transform: uppercase;
}

.smallerimg>img {
    width: 120px;
    height: 120px;
    margin: 4px;
}

/* Title */
.newProductDetailBigImg{
    max-width: 100%;
    max-height: 365px;
    width: 100%;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageColumnNPD{
    user-select: none;
}

.newProductDetailBigImg .iiz__img{
    max-width: 100%;
    max-height: 365px;
    object-fit: contain;
}

/* Price */
.price {
    font-size: var(--desktop-subheading);
    font-weight: 400;
    
    letter-spacing: 0em;
    text-align: left;
}

.reducedprice {
    height: 24px;
    width: 68px;
    left: 904px;
    top: 272px;
    border-radius: null;
    font-size: var(--desktop-text);
    white-space: nowrap;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: line-through;
    margin-left: 5px;
    margin-right: 15px;
}

.newProductSpecificationsTitle{
    margin-top: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: var(--desktop-subheading);
    
    text-transform: capitalize;
    color: #2E2E2E;
}

.newProductSpecificationDivHeading{
    font-weight: 500;
    padding-top: 13px;
    padding-bottom: 13px;
}

.newProductDescriptionDescription{
    margin-bottom: 40px;
}

.newProductDetailCouponImg{
    margin-right: 8px;
}

.countdownTimerNPD span{
    font-weight: 600;
    letter-spacing: 0.5px;
}

.newProductDetailCoupon{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-text);
    color: #2E2E2E;
}

.newProductViewCriteria, .newProductViewCriteria:hover{
    color: #ff7703;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-text);
}


.newProductSpecificationSpec{
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
}

.newProductDetailHr{
    height: 1px;
    width: 100%;
    margin: 0;
    background: #878787
}

.newProductAverageRating{
    color: #ff7703;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 96px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 45px;
    padding-right: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-right: 1px solid #878787;
}

.newProductRatingDiv_span{
    width: 300px !important;
}

.newProductRatingNumber{
    margin: 0;
}

.newProductReviewAuthor{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-text);
    color: #2E2E2E;
}

.progress{
    height: 5px;
    width: 100%!important;
}

.progress-bar{
    height: auto;
    background-color: #ff7703!important;
}

.newProductReviewUserDescription{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: var(--desktop-subtext);
    color: #2E2E2E;
}

.newProductReviewUserImage{
    object-fit: cover;
    max-height: 190px;
    width: 100%;
}

.newProductReviewDropdownButton{
    width: fit-content !important;
    padding: 14px 30px;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px !important;
    margin-bottom: 30px;
    border: 1px solid #ff7703;
}

.newProductAddToCartButton{
    background-color: transparent;
    border: none;
}

.getPriceNPD{
    padding: 5px 10px;
    border: none;
    background: #ff7703;
    cursor: pointer;
    color: white;
}

.newProductVariationBox{
    cursor: pointer;
    border: 1px solid #8A5B40;
    color: #2E2E2E;
    background: transparent;
    padding: 4px 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    user-select: none;
}

.newProductSelectedVariation{
    background: #8A5B40;
    color: white;
}

.newProductReviewInsideDiv{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.newProductReviewDate{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-subtext);
    
    color: #878787;
}

.reviewsAction{
    margin-right: 0;
    margin-left: auto;
}

.newProductReviewRatingSmall{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-subtext);
    
    background-color: #00BB07;
    padding: 2px 3px;
    color: white;
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    display: inline;

}

.newProductReviewInnerDiv{
    border: 1px solid #878787;
    max-width: 350px;
    margin: auto;
    height: 100%;
    justify-content: space-between;
}

.newProductReviewFooter{
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
}

.newProductReviewSingleDiv{
    padding: 15px;
}

.newProductRatingBar{
    height: 6px;
    background: #ff7703;
    color: white;
    border-radius: 50px;
}

.discount {
    background-color: #449a00;
    height: 32px;
    width: 59px;
    left: 987px;
    top: 266px;
    font-size: var(--desktop-text);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    padding: 3px 5px 2px 5px;
    color: white;
}

/* Description */
.description {}

.desc__title {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 400;
    ;
    letter-spacing: 0em;
}

.desc__content {
    font-size: var(--desktop-text);
    font-weight: 300;
    letter-spacing: 0em;
    margin-bottom: 0px !important;
}

.description>a {
    color: #ff7703;
    text-decoration: none;
}

.rowDetail {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -5px !important;
    justify-content: space-between;
}

.effect_on_heart:hover {
    cursor: pointer;
    transform: scale(1.3);
    transition: 0.1s all ease-in-out;
    /* object-fit: cover; */
}

.rowDetail>.product_list_heart>svg {
    font-size: 30px !important;
}

/* Variation */
.variation {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    ;
    display: flex;
    align-items: center;
    /* border:1px solid #FF7703; */
}

.variation__dropdown {
    text-decoration: underline;
    padding-left: 2px;
    cursor: pointer;
}

/* Dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 191px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.active-image {
    border: 3px solid #ff7703 !important;
}

.dropdown-content>a:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

/* Arrow */
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 16px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

/* Button */
.buy_btn {
    margin-top: 30px;
}

.buynow {
    width: 50%!important;
    border-radius: 0px;
    background-color: #ff7703 !important;
    color: white !important;
    margin-right: 14px;
    max-width: 100%;
    font-size: var(--desktop-text);
    font-weight: 400;
    display: inline;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    text-align: center;
}

.newProductAddToCartButton{
    width: auto!important;
}

.newProductAddToCartButton img{
    width: 85%;
}

.buynow:hover,
.buynow:focus {
    /* background-color: #ffffff !important; */
    box-shadow: none !important;
    color: white;
    /* transform: translateY(-1px); */
}

span>.addReview {
    width: 20%;
    margin: 10px;
}

.fontSizeClass{
    font-size: var(--desktop-text) !important;
}

.addReview {
    height: 40px;
    width: 100%;
    border-radius: 0px;
    background-color: #ff7703;
    border: 1px solid #ff7703;
    color: white;
    font-size: var(--desktop-text);
    font-weight: 400;
}

.addReview:hover,
.addReview:focus {
    /* background-color: #ffffff !important; */
    box-shadow: none !important;
    /* border: 1px solid #ffffff; */
    color: white;
    /* transform: translateY(-1px); */
}

.addtocart:hover,
.addtocart:focus {
    /* background-color: #ff7703 !important; */
    box-shadow: none !important;
    color: black !important;
    /* transform: translateY(-1px); */
}

.addtocart {
    width: 100%;
    height: 40px;
    left: 1040px;
    top: 609px;
    max-width: 100%;
    border-radius: 0px;
    border: 1px solid #ff7703;
    box-sizing: border-box;
    font-size: var(--desktop-text);
    font-weight: 400;
}

/* Rating */
.deleteReviewBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deleteReview_Actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.rating__title {
    font-size: var(--desktop-subheading);
    font-weight: 400;
    
    margin-top: 24px;
}

.reviewsAction {
    display: flex;
    justify-content: space-between;
    width: 33%;
    height: 27.2px;
    align-items: center;
}

.editReview>path:last-child {
    fill: #ff7703;
}

.reviewsAction>svg:hover {
    cursor: pointer;
    /* transform: translateY(-1px); */
}

.reviewsAction>svg {
    height: 30px;
    width: 30px;
}

.rating__title>span {
    font-size: var(--desktop-text);
    font-weight: 400;
    
    margin-left: 11px;
    color: var(--grey-color);
}


/* Line */
.line {
    border: 1px solid black;
}

.submitReview {
    width: fit-content;
    background-color: #ff7703 !important;
    border: 1px solid #ff7703 !important;
}

span>.submitReview {
    left: 642px;
    top: 649px;
    border-radius: 0px;
    padding: 10px 20px;
    font-family: Poppins;
    font-size: var(--desktop-subheading);
    font-weight: 600;
    
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.submitReview:hover,
.submitReview:focus {
    background-color: #ffffff !important;
    border: 1px solid #ff7703;
    box-shadow: none !important;
    color: #ff7703 !important;
    /* transform: translateY(-2px); */
}

.rating>a {
    color: #ff7703;
}

/* Star */
.star-widget input {
    display: none;
}

.star-widget label {
    font-size: var(--desktop-text);
    color: black;
    margin-top: 8px;
    padding: 3px;
    transition: all 0.2s ease;
    cursor: pointer;
}

.star-widget {
    transform: rotate(360deg);
}

input:not(:checked)~label:hover,
input:not(:checked)~label:hover~label {
    color: #ff7253;
}

input:checked~label {
    color: #ff7253;
}

input#rate-5:checked~label {
    color: #ff7253;
    text-shadow: 0 0 20px #ff7253;
}

/* Rating Modal */
.addRatingModalBody {
    display: flex;
    flex-direction: column;
}

.addRatingModalBody > form > div > textarea{
    resize: none;
}
.css-jue3ft-MuiRating-root{
    color: #FF7253 !important;
}

.starRating_master > span > label > span > svg {
    fill: #FF7253 !important;
}

.ProgessRating{
    height: 6px !important;
    border-radius: 0px !important;
}
/* Media Queries */
@media screen and (max-width: 1400px) {
    /* .smallerimg > img {
    width: 137px;
  } */

    .leftcontainer {
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 992px) {
    .smallerimg {
        justify-content: center;
    }

    .newProductRatingDiv_span{
        width: 250px !important;
    }
    
    .newProductTitle{
        font-size: var(--tablet-heading);
    }
    .newProductSubtitle{
        font-size: var(--tablet-subheading);
    }
    .price{
        font-size: var(--tablet-subheading);
    }
    .reducedprice{
        font-size: var(--tablet-text);
    }
    .newProductSpecificationsTitle{
        font-size: var(--tablet-subheading);
    }
    .newProductDetailCoupon{
        font-size: var(--tablet-text);
    }
    .newProductViewCriteria, .newProductViewCriteria:hover{
        font-size: var(--tablet-text);
    }
    .newProductReviewAuthor{
        font-size: var(--tablet-text);
    }
    .newProductReviewUserDescription{
        font-size: var(--tablet-subtext);
    }
    .newProductReviewDate{
        font-size: var(--tablet-subtext);
    }
    .newProductReviewRatingSmall{
        font-size: var(--tablet-subtext);
    }
    .discount{
        font-size: var(--tablet-text);
    }
    .desc__content{
        font-size: var(--tablet-text);
    }
    .buynow{
        font-size: var(--tablet-text);
    }
    .fontSizeClass{
        font-size: var(--tablet-text) !important;
    }
    .addReview{
        font-size: var(--tablet-text);
    }
    .addtocart{
        font-size: var(--tablet-text);
    }
    .rating__title{
        font-size: var(--tablet-subheading);
    }
    .rating__title>span{
        font-size: var(--tablet-text);
    }
    span>.submitReview{
        font-size: var(--tablet-subheading);
    }
    .star-widget label {
        font-size: var(--tablet-text);
    }
}

@media screen and (max-width: 768px) {
    .biggerimg>img {
        margin-right: 13px;
        /* width: 500px; */
    }

    .newProductRatingDiv_span{
        width: 250px !important;
    }

    .newProductTitle{
        font-size: var(--tablet-heading);
    }
    .newProductSubtitle{
        font-size: var(--tablet-subheading);
    }
    .price{
        font-size: var(--tablet-subheading);
    }
    .reducedprice{
        font-size: var(--tablet-text);
    }
    .newProductSpecificationsTitle{
        font-size: var(--tablet-subheading);
    }
    .newProductDetailCoupon{
        font-size: var(--tablet-text);
    }
    .newProductViewCriteria, .newProductViewCriteria:hover{
        font-size: var(--tablet-text);
    }
    .newProductReviewAuthor{
        font-size: var(--tablet-text);
    }
    .newProductReviewUserDescription{
        font-size: var(--tablet-subtext);
    }
    .newProductReviewDate{
        font-size: var(--tablet-subtext);
    }
    .newProductReviewRatingSmall{
        font-size: var(--tablet-subtext);
    }
    .discount{
        font-size: var(--tablet-text);
    }
    .desc__content{
        font-size: var(--tablet-text);
    }
    .buynow{
        font-size: var(--tablet-text);
    }
    .fontSizeClass{
        font-size: var(--tablet-text) !important;
    }
    .addReview{
        font-size: var(--tablet-text);
    }
    .addtocart{
        font-size: var(--tablet-text);
    }
    .rating__title{
        font-size: var(--tablet-subheading);
    }
    .rating__title>span{
        font-size: var(--tablet-text);
    }
    span>.submitReview{
        font-size: var(--tablet-subheading);
    }
    .star-widget label {
        font-size: var(--tablet-text);
    }
}

@media screen and (min-width: 768px) {
    .biggerimg {
        /* height: 500px;
    width: 500px; */
        /* width: 715px; */
        /* height: 600px; */
    }

    .leftcontainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .newProductRatingDiv_span{
        width: 250px !important;
    }
}


@media screen and (max-width: 636px) {
    .smallerimg {
        justify-content: left;
    }

    .newProductRatingDiv_span{
        width: 250px !important;
    }

    .newProductTitle{
        font-size: var(--tablet-heading);
    }
    .newProductSubtitle{
        font-size: var(--tablet-subheading);
    }
    .price{
        font-size: var(--tablet-subheading);
    }
    .reducedprice{
        font-size: var(--tablet-text);
    }
    .newProductSpecificationsTitle{
        font-size: var(--tablet-subheading);
    }
    .newProductDetailCoupon{
        font-size: var(--tablet-text);
    }
    .newProductViewCriteria, .newProductViewCriteria:hover{
        font-size: var(--tablet-text);
    }
    .newProductReviewAuthor{
        font-size: var(--tablet-text);
    }
    .newProductReviewUserDescription{
        font-size: var(--tablet-subtext);
    }
    .newProductReviewDate{
        font-size: var(--tablet-subtext);
    }
    .newProductReviewRatingSmall{
        font-size: var(--tablet-subtext);
    }
    .discount{
        font-size: var(--tablet-text);
    }
    .desc__content{
        font-size: var(--tablet-text);
    }
    .buynow{
        font-size: var(--tablet-text);
    }
    .fontSizeClass{
        font-size: var(--tablet-text) !important;
    }
    .addReview{
        font-size: var(--tablet-text);
    }
    .addtocart{
        font-size: var(--tablet-text);
    }
    .rating__title{
        font-size: var(--tablet-subheading);
    }
    .rating__title>span{
        font-size: var(--tablet-text);
    }
    span>.submitReview{
        font-size: var(--tablet-subheading);
    }
    .star-widget label {
        font-size: var(--tablet-text);
    }
}

@media screen and (max-width: 576px) {
    .price,
    .rating__title {
        font-size: 18px;
    }
    .reducedprice {
        font-size: 14px;
    }
    .smallerimg {
        justify-content: center;
    }

    .newProductRatingDiv_span{
        width: 250px !important;
    }

    .newProductTitle{
        font-size: var(--mobile-heading);
    }
    .newProductSubtitle{
        font-size: var(--mobile-subheading);
    }
    .price{
        font-size: var(--mobile-subheading);
    }
    .reducedprice{
        font-size: var(--mobile-text);
    }
    .newProductSpecificationsTitle{
        font-size: var(--mobile-subheading);
    }
    .newProductDetailCoupon{
        font-size: var(--mobile-text);
    }
    .newProductViewCriteria, .newProductViewCriteria:hover{
        font-size: var(--mobile-text);
    }
    .newProductReviewAuthor{
        font-size: var(--mobile-text);
    }
    .newProductReviewUserDescription{
        font-size: var(--mobile-subtext);
    }
    .newProductReviewDate{
        font-size: var(--mobile-subtext);
    }
    .newProductReviewRatingSmall{
        font-size: var(--mobile-subtext);
    }
    .discount{
        font-size: var(--mobile-text);
    }
    .desc__content{
        font-size: var(--mobile-text);
    }
    .buynow{
        font-size: var(--mobile-text);
    }
    .fontSizeClass{
        font-size: var(--mobile-text) !important;
    }
    .addReview{
        font-size: var(--mobile-text);
    }
    .addtocart{
        font-size: var(--mobile-text);
    }
    .rating__title{
        font-size: var(--mobile-subheading);
    }
    .rating__title>span{
        font-size: var(--mobile-text);
    }
    span>.submitReview{
        font-size: var(--mobile-subheading);
    }
    .star-widget label {
        font-size: var(--mobile-text);
    }
    .newProductAverageRating{
        padding-left: 5px;
        padding-right: 15px;
    }
}