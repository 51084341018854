.cartNavbarPlypickerLogo{
    position: absolute;
    left: 2%;
    cursor: pointer;
}

.cartNavbar{
    margin-bottom: 60px;
}

.cartNavbar{
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}

.stepper{
    max-width: 60%;
    min-width: 60%;
}

.stepper{
    margin-top: 0!important;
}

/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #50C878!important;
} */
.stepper_div > span > span > .Mui-completed{
    color: #50C878!important;
}

@media (max-width: 992px){
    .stepper{
        max-width: 80%;
        margin-top: 3em!important;
        /* max-width: 80%; */
    }
    .cartNavbarPlypickerLogo{
        top: 2%;
    }
}

@media (max-width: 576px){
    .cartNavbarPlypickerLogo{
        display: none;
    }
    .stepper{
        margin-top: 0!important;
        max-width: 100%;
        min-width: 100%;
    }
    .cartNavbar{
        flex-direction: column;
        margin-bottom: 0px !important;
    }
}