.current_orders_grid {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  width: 100px;
}

.yourOrderDeliveryStepperDiv .Mui-completed>span>span>.Mui-completed {
  color: #50C878 !important;
}

.yourOrderDeliveryStepperDiv .MuiStepLabel-label {
  margin-top: 5px !important;
}

.current_orders_grid .current_orders_grid_element {
  width: 50%;
  height: 50%;
}

.deleteOrderConfirmationModal{
  border-radius: 0!important;
}

.yourOrdersVariationBox {
  border: 1px solid #FF7703;
  cursor: default;
  text-transform: capitalize;
  user-select: none;
  white-space: nowrap;
  font-weight: initial;
  font-size: initial;
  display: inline-block;
  width: fit-content;
}

.yourOrderTax {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  display: flex;
  align-items: center;
  color: #878787;
  justify-content: end;
}

.yourOrderInfoDiv {
  flex: 1;
}

.hoverOrderClass>span:hover {
  cursor: pointer;
}

.hoverOrderClass>span:hover {
  color: #FF7703 !important;
}

.current_orders_grid .current_orders_grid_element_3 {
  width: 100%;
}

.yourOrdersLoadingDiv {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.current_orders_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.fullWidthCol {
  width: auto;
}

.current_orders_grid img {
  /* width: -webkit-fill-available; */
  width: 100%;
  height: 100%;

}

.imageColumn {
  height: 152px;
  width: 124px;
}

.currOrdersImg {
  object-fit: cover;
  height: inherit;
  object-position: center;
  min-width: 124px;
}

.singleCurrentOrderContainer {
  border: 1px solid #878787
}

.show-prop:hover {
  cursor: pointer;
}

.currentOrdersScrollbar::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
.currentOrdersScrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(181, 181, 181);
  border-radius: 6px;
}

/* Handle */
.currentOrdersScrollbar::-webkit-scrollbar-thumb {
  background: #fd7e14;
  border-radius: 10px;
}

/* Handle on hover */
.currentOrdersScrollbar::-webkit-scrollbar-thumb:hover {
  background: #c4671b;
}

.pagination-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-btn{
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-btn.active{
  background-color: #FF7703;
  color: #fff;
  border: 1px solid #FF7703;
}

.pagination-btn:hover{
  background-color: #FF7703;
  color: #fff;
  border: 1px solid #FF7703;
}

.yourOrderReviewButton {
  background: #FF7703;
  border: 1px solid #FF7703;
  border-radius: 4px;
  padding: 6px 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: white;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.yourOrdersReviewModalHeading {
  margin-left: 50%;
  transform: translate(-50%, 0);
  /* margin-top: 50%; */
}

.detailColumn {
  display: flex;
  flex-direction: column;
  /* height: 152px; */
  justify-content: space-between;
}

.current_orders_display_info {
  display: flex;
  /* padding-right: 20px; */
  flex-direction: column;
}

.renderside_current_orders {
  margin-bottom: 30px;
  border-bottom: 1px solid #878787;
  margin-right: 10px;
  padding-bottom: 20px;
}

.current_order_renderside_info {
  width: 400px;
}

.orderInvoice:hover {
  transform: translateY(-2px);
  color: #fd7e14;
}

.currentorders_img {
  flex: 0.15;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.currentorders_img::-webkit-scrollbar {
  display: none;
}

.currentorders_img img {
  width: 130px;
  height: 130px;
}

.currentorder_row1_img1 {
  margin-right: 2px;
  margin-bottom: 2px;
}

.currentorder_row2_img1 {
  margin-right: 2px;
}

.currentorders_info {
  /* flex: 0.9; */
  /* padding-left: 20px; */
  margin-bottom: 20px;
  flex-grow: 1
}

.currentorders_img_row1,
.currentorders_img_row2 {
  display: flex;
}

.current_orders_title {
  display: flex;
  justify-content: space-between;
}

.current_orders_deliverydate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  ;
  display: flex;
  align-items: center;
  color: #000000;
}

.current_orders_deliverydate>span {
  color: #8a5b40;
  text-decoration: underline;
  cursor: pointer;
}

.current_orders_green {
  color: #00BB07;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  ;
}

.current_orders_orderID {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  display: flex;
  align-items: center;
  color: #878787;
}

.current_orders_address {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 15px;
}

.current_orders_collapsebtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #8a5b40;
}

.current_orders_detailed_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  /* padding-right: 20px; */
}

.current_orders_detailed_info_left {
  flex: 0.6;
}

.current_orders_detailed_info_right {
  flex: 0.4;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}

.detailedinfo_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  ;
  color: #000000;
}

.downArrowBtn {
  height: 18px;
  width: 20px;
  margin-right: 5px;
}

.detailedinfo_brand {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  ;
  color: 878787;
}

.detailedinfo_quantity {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  ;
  color: #000000;
}

.detailedinfo_amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  ;
  color: #000000;
}

.orderComponentImg{
  width: 133px;
  height: 133px;
  object-fit: contain;
}

.mw-100{
  max-width: 100%;
}

.orderComponentOrderDetailDiv{
  width: -moz-available;
  width: -webkit-fill-available;
}

.deleteOrderConfirmationModal{
  border-radius: 0px!important;
}

@media screen and (max-width: 1200px) {
  .current_orders_deliverydate {
    font-size: 18px;
    ;
  }

  .detailedinfo_name {
    font-size: 18px;
    ;
  }

  .detailedinfo_brand,
  .detailedinfo_quantity,
  .detailedinfo_amount {
    font-size: 16px;
    ;
  }
}

.current_orders_title_rightside {
  width: fit-content;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  ;
}

.current_orders_title_rightside>a:hover {
  color: #FF7703 !important;
}

.current_orders_title_rightside>a:hover>svg {
  fill: #FF7703;
}

.currentOrderAddress {
  overflow: auto;
  text-overflow: ellipsis;
}

.cancelOrderBtn{
  white-space: nowrap;
  border: 1px solid #dc3545;
  background-color: #dc3545f0;
  border-radius: 0;
  padding: 5px 30px!important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  height: 35px;
  min-height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
}

@media screen and (max-width:1320px) {
  .cancelOrderBtn {
    width: 150px !important;
  }
}

@media screen and (max-width: 768px) {
  .detailColumn .row {
    justify-content: flex-start;
  }

  .orderContainer {
    justify-content: center;
    align-items: center;
  }

  .current_orders_detailed_info {
    position: relative;
    padding-right: 0;
    margin-bottom: 30px;
  }

  .detailedinfo_brand {
    position: absolute;
    top: 22px;
    left: 0;
  }

  .current_orders_display_info {
    flex-direction: column;
  }

  .currentorders_info {
    padding-left: 0;
    margin-top: 20px;
  }

  .currentorders_img {
    flex-direction: row;
    width: 100%;
  }

  .currentorders_img_row1,
  .currentorders_img_row2 {
    flex: 0.5;
    justify-content: space-around;
  }

  .renderside_current_orders {
    margin-right: 0px;
  }

  .imageColumn {
    width: 200px;
  }

  .current_orders_deliverydate,
  .current_orders_title_rightside {
    font-size: 16px;
    ;
  }

  .current_orders_orderID,
  .current_orders_address {
    font-size: 14px;
    
  }

  .detailedinfo_name {
    font-size: 16px;
    ;
  }

  .detailedinfo_brand,
  .detailedinfo_quantity,
  .detailedinfo_amount {
    font-size: 14px;
    
  }

  .current_orders_detailed_info_left {
    flex: 0.6;
  }

  .current_orders_detailed_info_right {
    flex: 0.4;
  }
}

@media screen and (max-width: 576px){
  
    .current_orders_deliverydate,
    .current_orders_title_rightside {
      font-size: 12px;
      ;
    }

    .orderComponentProductName{
      margin-bottom: 0px !important;
    }
    .yourOrdersVariationBox{
      font-size: var(--mobile-text);
    }
    .yourOrdersVariationBox{
      margin-bottom: 5px !important;
    }
    .current_orders_orderID{
      font-size: var(--mobile-text);
    }
    .priceOrder > p{
      font-size: var(--mobile-text) !important;
    }
    .moreButton{
      font-size: 14px !important;
    }
}