.cardServiceCat:hover {
  cursor: pointer;
}

.cardServiceCat > img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  min-height: 370px;
}
.inputLabelsService {
  color: #878787 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
}

.Totalprice {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.hireConButton {
  padding: 20px 30px !important;
  color: #ffffff !important;
  background-color: #ff7703 !important;
  border: 1px solid #ff7703 !important;
  border-radius: 0px !important;
}

.inputServiceNum::-webkit-outer-spin-button,
.inputServiceNum::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
.inputServiceNum {
  -moz-appearance: textfield;
}

@media screen and (min-width: 2200px) {
  .cardServiceCat > img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    min-height: 400px;
  }
}
