.maxWidth100{
  max-width: 100%;
}

@-moz-document url-prefix() {
  .navupper_question, .navupper_fav, .navupper_cart, .navupper_profile{
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.upperNavbarGap{
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar_upper_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .navbar_upper_outer{
  height:8vh;
  justify-content: center;
  display: flex;
  align-items: center;
} */

.navupper_logo {
  flex: 0.2;
  font-family: "Trirong";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  ;
  color: #000000;
  text-decoration: none;
  /* margin-left: -55px; */
  color: #000000;
}

.navupper_search_area{
    width: 100%
}

.upperNavbarIconCursorPointer{
  cursor: pointer;
}

.navupper_logo a {
  color: black;
}

/* .navbar_upper_outer{
  border-bottom: 1px solid #000000;
} */

.navupper_right {
  display: flex;
  flex: 0.75;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #000000;
}

.navupper_search {
  display: flex;
  flex: 0.6;
  background: #ebebeb;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 45px
}

.navupper_search input {
  border: none;
  outline: none;
  background: transparent;
}

.upperNavbarIconsWrapper{
  max-width: 195px;
  width: 195px;
}

@media screen and (max-width: 576px) {
  .upperNavbarIconsWrapper{
    max-width: 188px;
    width: 188px;
  }
}

@media screen and (max-width: 576px) {
  .navbar_upper_main{
    flex-direction: column;
    align-items: flex-start;
  } 
  .navupper_search{
    flex: 1 1 !important;
  }
  .navupper_right{
    width: 100%;
  }
}