.chatBotWrapper{
    width: fit-content;
    position: fixed;
    right: 3rem;
    bottom: 2rem;
    z-index: 2;
}

.openChatBotButton{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    background: #FF7703;
    color: black;
}

.chatBotInput{
    width: 100%;
    border-radius: 0;
    outline: none;
    color: black;
    border: 0.5px solid #b8b8b8;
    padding: 0.15rem 0.5rem;
}

.chatBotDiv{
    width: 300px;
    min-height: 400px;
    background: white;
    position: relative;
}

.closeChatBotButton{
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
    font-size: 20px;
    background: transparent;
    border: none;
}

.closeChatBotButton > svg{
    stroke-width: 30px;
}

@media screen and (max-width: 576px){
    .chatBotWrapper{
        right: 1rem;
        bottom: 4rem;
    }
}