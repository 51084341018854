.starRating_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -2px !important;
    /* margin: 10px 0; */
    /* justify-content: center; */
}

.starRating_main > span > span > span > span > svg {
    fill: #FF7253 !important;
}

.starRating_star {
    cursor: pointer;
    transition: color 200ms;
}

.css-1x1lh1c-MuiRating-root{
    color: #FF7253 !important;
}