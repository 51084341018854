.navroute{
    padding-left: 30px !important;
    margin-top: 22px;
    margin-bottom: 33px;
    text-transform: uppercase;
}

.navroute > a{
    text-decoration: none;
}

.navroute > a:last-child{
    color: #8A5B40;
    text-decoration: underline;
}
.nav__route__outer{
    border-bottom: 1px solid #878787;
    padding-top: 53px;
    padding-bottom: 15px;
    padding-left:0px;
}
.nav__route__curr{
    color: #8A5B40;
}

.navRouteLink{
    color: black;
}

.navRouteLink:hover{
    color: black;
}