.renderside_cart {
  padding: 20px 0;
  /* margin: 0 20px; */
}

.cartside_total_subtotal .cartside_total_text, .cartside_total_subtotal .cartside_total_amount{
  font-weight: 500;
}

.checkoutModalBody {
  display: flex;
  flex-direction: column;
}

.borderedContainer {
  border: 1px solid #878787;
  /* padding: 1em; */
  margin-bottom: 2em;
}

.cartSidebarSubheadings{
  font-size: 1.4em;
  font-weight: 500;
}

.cartSubtext{
  color: #878787;
  font-size: 10px;
}

.checkoutProductLink{
  color: black;
  text-decoration: none;
}

.checkoutProductLink:hover{
  color: black;
}

.alertModalh1{
  font-family: "Poppins";
  font-weight: 500;
  font-size: 25px;
}
/* 
.alertModalName > .modal-dialog > .modal-content{
  width: 85% !important;
} */

.checkoutProductTitleWrapper{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.checkoutVariationBox{
  border: 1px solid #FF7703;
  cursor: default;
  text-transform: capitalize;
  user-select: none;
  color: black;
}

.checkoutVariationBox:focus-visible{
  outline: none!important;
}

.checkoutAddressName{
  color: black!important;
  font-weight: normal!important;
}

.flex1Inner{
  flex: 1;
}

.cartBrand{
  text-transform: capitalize;
  color: #878787;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.cartImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cartInline {
  display: inline;
}

.orderButtons {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.inside_addressTypeData {
  display: flex;
  width: 100%;
  align-items: center;
}

.inside_addressTypeData>h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-size: var(--desktop-text);
}

.cartBox > p{
  color: #878787;
}

.cartBox > p > b{
  color: #000000 !important;
}
.coupon-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.coupon-item {
  padding: 10px;
  border: 1px solid #ddd;
}

.coupon-details{
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding : 3px;
}
.coupon-item-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
.coupon-title{
  font-family: "Poppins";
  color: #ff7703;
  border: 0.5px dashed #ff7703;
}
/* .coupon-title:hover{
  background-color: #ffffff;
} */
.coupon-apply-button {
  border: 0.5px solid #ff7703;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 8px;
  text-align: center;
  justify-self: center; 
  margin-bottom: 5px;
  width: 95%;
}
.form{
  justify-content: space-between;
}
.coupon-item-details:hover{
  background-color: #FEF4EC;
  border: 0.5px solid #ff7703;
  .coupon-title{
    background-color : #ffffff;
  }
  .coupon-apply-button{
    background-color: #ff7703;
  }
}
.maxUses{
  color: #ff7703;
}

.inside_addressTypeData>span {
  margin-left: 15px;
  margin-bottom: 5px;
  color: #878787;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  
  text-transform: uppercase;
}

.delivery_address_heading {
  font-weight: 600;
  font-size: 24px;
  
}
.search-bar{
  height : 42px;
  border : 0.5px solid #F5F5F5;
  border-radius: 10px;
  justify-content: space-between;
  background-color: #F5F5F5;
  margin-bottom: 9px;
}
.search-input{
  outline : none;
  margin-left: 10px;
  text-decoration: none;
}
.coupon-apply-button:hover{
  background-color: #FF7703;
}

.select_address_left {
  margin-right: 13px;
}

.select_address_left>svg {
  transform: scale(1.5);
  stroke: #878787;
}

.map-container{
  width: 100%;
  height: 300px;
}

.cartLaborInfo{
  font-size: var(--desktop-subtext)
}

.MuiSwitch-thumb{
  box-shadow: 0px 2px 1px -1px #ff7703, 0px 1px 1px 0px #ff7703, 0px 1px 3px 0px #ff7703!important;
  background-color: #ff7703!important;
}

.MuiTouchRipple-root{
  
}

.Mui-checked+.MuiSwitch-track{
  background: transparent!important;
  border: 1px solid #878787!important;
}

.MuiSwitch-track{
  background: #878787!important;
}

.deliveryNotApplicable{
  font-size: 9px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FF3A44B2;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.floorInputBox, .floorInputBox:focus, .floorInputBox:focus-visible, .floorInputBox:focus-within, .floorInputBox:active, .floorInputBox:hover{
  background: #F1F1F1;
  border: none;
  outline: none;
}

.cartLaborVariation{
  color: #878787;
  text-transform: capitalize;
}

.cartLaborProduct{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productDiscountedPrice{
  color: #8A5B40;
  font-weight: 500;
}

.productPrice{
  text-decoration: line-through;
  color: #2E2E2E;
}

.productDiscountedPercent{
  color: #449A00;
}

.deliveryVehicleButton{
  width: 100%;
  height: 60px;
  background-color: transparent;
  outline: none;
  border: 2px solid #878787
}

.deliveryVehicleButtonActive{
  border: 2.5px solid var(--plypicker-primary);
  background: url(./img/circle.png) no-repeat center center/cover;
  background-size: auto;
}

.deliveryVehicleImg{
  max-width: 100%;
  max-height: 100%;
}

.select_address_left>svg>path {
  fill: #D9D9D9;
}

.select_address:hover {
  /* transform: translateY(-2px); */
  cursor: pointer;
}

.select_address:hover>.profile_form_content_inside_data>.select_address_left>svg {
  stroke: #fd7e14;
}

.select_address:hover>.profile_form_content_inside_data>.select_address_left>svg>path {
  fill: #FF7253;
}
input[type='radio'] {
  accent-color: #FF7253;
  border: 0px;
    width: 100%;
    height: 2em;

}

.select_address {
  padding: 10px !important;
  background: #ffffff;
  border: 0.5px solid #b8b8b8;
  /* padding: 2px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  /* margin-bottom: 10px; */
}

.address_type_mid {
  font-family: 'Poppins';
  width: 16%;
  font-style: normal;
  font-weight: 500;
  color: #878787;
  font-size: 14px;
  
  background: #D9D9D9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.address_name_mid {
  font-weight: 500 !important;
  font-size: 16px;
  color: #000000;
}

.address_add_mid {
  color: #878787;
  font-size: 14px;
  font-weight: normal !important;
}

.select_address_mid {
  display: flex;
  flex-direction:  column !important;
  width: 100%;
}

.inside_address_mid {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.checkAddress {
  /* margin-top: 10px; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkAddress>h6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  ;
}

.checkAddress>span {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.checkAddress>span>input {
  border: 1px solid #878787;
  padding: 10px;
  width: 60%;
  margin-bottom: 0px !important;
}

.change_delivery_address {
  padding: 30px 18px;
  /* background: #e5e5e5; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.checkAddress>span>button {
  width: 158px;
  height: 46px;
  background-color: #FF7253;
  border: 1px solid #FF7253;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  ;
  color: #ffffff;
}

.checkoutModalBody>.profile_form_content:hover {
  cursor: pointer;
  background-color: #FF7703;
  color: #ffffff;
}

.checkoutModalBody>.profile_form_content>.profile_form_content_inside:hover {
  color: #ffffff;
}

.cart_display_info {
  display: flex;
}

.renderside_cart {
  height: 160px;
  border-bottom: 1px solid #878787;
}

.cart_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
}

.cart_info_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  ;
  color: #000000;
}

.cart_info_company {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  ;
  color: #878787;
}

.cart_info_quantity {
  display: flex;
}

.cart_info_quantity_number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  ;
  color: #000000;
}

.cart_info_quantity_remove {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  ;
  text-decoration-line: underline;
  color: #8a5b40;
  margin-left: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.cart_info_quantity_remove:hover {
  /* transform: translateY(-2px); */
  color: #fd7e14;
}

.cart_info_price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  ;
  color: #000000;
}

.renderside_cartside_total_cart {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border: 0.5px solid #878787;
  width: inherit;
}
.coupon_side{
  margin-left:15px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
  margin-bottom: 15px;
  border: 0.5px solid #ff7703;
  width: inherit;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  height:50px;
  font-size: 21px;
  background-color: #ff7703;
  color: #ffffff;
}
.coupon_side:hover{
  cursor : pointer
}

.cartside_total_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  
  color: #000000;
}

.cartside_total_total,
.cartside_total_discount,
.cartside_total_tax,
.cartside_total_subtotal {
  display: flex;
  justify-content: space-between;
}
.arrowimg{
  width: 30px;
}

.cartside_total_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
}
.coupon-applied-message{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  ;
  color: #000000;
}

.cartside_total_amount {
  /* font-family: "Poppins"; */
  font-style: normal;
  /* font-weight: 400; */
  /* font-size: 18px; */
  ;
  color: #000000;
}

.cartside_total_subtotal {
  margin-top: 10px;
  border-top: 1px solid black;
  /* border-bottom: 1px solid black; */
  padding: 10px 0;
}

.cartside_total_subtotal .cartside_total_text,
.cartside_total_subtotal .cartside_total_amount {
  font-size: 20px;
  ;
}

.changeAddressButton {
  background-color: transparent;
  border: 1px solid #FF7703;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: black;
}

.cartside_total_checkout {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  ;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 4px 10px;
  cursor: pointer;
  color: #ffffff;
  background: #FF7703;
  margin-top: 20px;
}

.cartside_total_checkout:hover {
  /* background-color: #ffffff; */
  /* box-shadow: 0px 2.5px 2.5px #FF7703; */
  /* color: #FF7703; */
  /* transform: translateY(-2px); */
}

.cartside_total_discount .cartside_total_amount{
  color: #FF7703;
}

.cart_img {
  height: 100px !important;
  width: 100px !important;
}

.cart_img>img {
  height: 100% !important;
  width: 100% !important;
}

@media screen and (max-width: 992px) {
  .renderside_cartside_img {
    display: block;
    width: 300px;
    margin-top: 50px;
  }

  .checkoutVariationBox{
    font-size: var(--tablet-text);
  }

  .cartBox > p{
    font-size: var(--tablet-text);
  }

  .inside_addressTypeData>h2{
    font-size: var(--tablet-text);
  }

  .renderside_cartside_total_cart{
  }

  .renderside_cartside_img_bottom {
    display: none;
  }

  .profile_renderside_cart {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .cart_info_quantity {
    flex-direction: column;
  }

  .cartBox > p{
    font-size: var(--tablet-text);
  }

  .checkoutVariationBox{
    font-size: var(--tablet-text);
  }

  .inside_addressTypeData>h2{
    font-size: var(--tablet-text);
  }

  .cart_info_quantity_remove {
    margin-left: 0;
  }

  .renderside_cart {
    margin-right: 0;
    position: relative;
  }

  .cart_info_price {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .cart_info_heading,
  .cartside_total_heading,
  .cartside_total_checkout {
    font-size: 18px;
  }

  .cart_info_company,
  .cart_info_quantity_number,
  .cart_info_quantity_remove,
  .cart_info_price,
  .cartside_total_text,
  .cartside_total_amount {
    font-size: 16px;
    ;
  }

  .cartside_total_heading {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .renderside_cart {
    margin-left: 0;
  }

  .checkoutVariationBox{
    font-size: var(--mobile-subtext);
    margin-bottom: 5px;
  }

  .cartside_total_checkout{
    font-size: var(--mobile-subheading);
    margin-top: 1px !important;
  }

  .cart_info_quantity_remove > img{
    height: 15px;
    width: 15px;
  }

  .deliveryDateCart{
    font-size: var(--mobile-text);
  }

  .cartBrand{
    font-size: var(--mobile-text);
  }

  .cartProductQuantity{
    font-size: 10px;
  }

  .checkoutProductLink{
    font-size: var(--mobile-subheading);
  }

  .addressTypeData{
    margin-bottom: 5px;
  }

  .cartBox > p{
    font-size: var(--mobile-text);
  }

  .inside_addressTypeData>span{
    font-size: 10px;
  }

  .inside_addressTypeData>h2{
    font-size: var(--mobile-text);
  }

  .cart_info_heading {
    font-size: 16px;
    ;
  }

  .cart_info_company,
  .cart_info_quantity_number,
  .cart_info_quantity_remove,
  .cart_info_price {
    font-size: 14px;
    
  }

  .cart_img img {
    height: 100%;
    width: 100%;
  }

  .cart_info_price {
    position: absolute;
    bottom: 40px;
    right: 0;
  }

  .renderside_cart {
    height: 140px;
  }
}

.cart-item-shimmer {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}
.shimmer-image {
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.shimmer-detail-container{
  display: flex;
  flex: 1;
}
.shimmer-details {
  flex: 1;
  padding-left: 10px;
  margin-top: 2%;
}
.shimmer-details-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.shimmer-line {
  height: 16px;
  width: 50%;
  margin-bottom: 5px;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}

@keyframes shimmer-animation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}