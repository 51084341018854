.ratingHeading{
    font-weight: 500;
    font-size: var(--desktop-heading);
    margin-top:64px;
    margin-bottom: 25px;
}
.selectedProduct{
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 25px 20px;
gap: 20px;
/* background:#fff; */
}
.selectedProductDescription h1{
    font-weight: 500;
font-size: var(--desktop-subheading);
color: #2E2E2E;
/* margin-bottom: 10px; */
}
.selectedProductDescription h2{
    font-weight: 400;
font-size: 24px;

color: #878787;
}
.selectedProductDescription{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
flex: none;
order: 1;
flex-grow: 0;
margin-left: 20px;
}
.uploadPhoto h1{
    font-weight: 500;
    font-size: var(--desktop-subheading);
    color: #2E2E2E;
}
.uploadPhoto h2{
font-weight: 400;
font-size: 24px;

color: #878787;
}
.uploadPhoto textarea::placeholder{
    margin: 3px;
    color:#878787
}
.uploadPhoto textarea{
    border: 1px solid #878787;
    padding: 5px;
}
.uploadPhoto input::placeholder{
    display: none;
}
.uploadPhoto label:hover{
    cursor: pointer;
}
.uploadPhoto label{
    border: 1px dashed #000000;
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 40px 55px;
gap: 10px;
font-size: 75px;
font-weight: 200;
}
.uploadPhoto input{
    visibility: hidden;
}
.uploadPhoto{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 25px 20px;
padding-left: 0px;
gap: 20px;
}
.reviewSubmitButtonContainer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 86px;
    margin-top: 15px;
}
.reviewSubmitButtonContainer button{
    border-radius: 1px;
}
@media only screen and (max-width: 1000px) {
.ratingHeading,.selectedProductDescription h1,.overallRating h1,.uploadPhoto h1{
    font-size: 26px;
}
.selectedProductDescription h2,.uploadPhoto h2{
    font-size: var(--desktop-text);
}
}
@media only screen and (max-width: 600px) {
.ratingHeading,.selectedProductDescription h1,.overallRating h1,.uploadPhoto h1{
    font-size: 22px;
}
.selectedProductDescription h2,.uploadPhoto h2{
    font-size: 13px;
}
}
@media only screen and (max-width: 450px) {
.ratingHeading,.selectedProductDescription h1,.overallRating h1,.uploadPhoto h1{
    font-size: 18px;
}
.selectedProductDescription h2,.uploadPhoto h2{
    font-size: 10px;
}
}