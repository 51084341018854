@-moz-document url-prefix() {
    .shopbyprice_product_image img{
        width: 100%;
    }
}

.sliderDiv *{
    cursor: grab!important;
}

.shopbyprice_image_row_slider > .slick-slider > .slick-arrow{
    display: none !important;
}

.shopbyprice_image_row_slider:hover> .slick-slider > .slick-arrow{
    display: block !important;
}
.shopbyprice_image_row_slider > .slick-slider > .slick-arrow::before{
    color: #FF7703 !important;
    font-size: var(--desktop-heading) !important;
}
.shopbyprice_image_row_slider > .slick-slider > .slick-prev{
    transform: translate(30px,-55px);
    z-index: 1;
}
.shopbyprice_image_row_slider > .slick-slider > .slick-next{
    transform: translate(-52px,-55px);
    z-index: 1;
}
.brandSlider_main>.slick-prev{
    transform: translate(30px,-23px) !important;
    z-index: 1;
}
.brandSlider_main >.slick-next{
    transform: translate(-52px,-23px) !important;
    z-index: 1;
}

.slider_button{
    height: 40px;
    width: 40px;
    border: 2px solid #000000;
    background: #ffffff;
    color: #8a5b40;
}
.slider_button > span { 
    font-weight: 400;
    font-size: var(--desktop-subheading);
}
.previous{
    margin-right: 10px;
    transform: rotate(180deg);
}

.shopbyprice_brand_name{
    font-family: Poppins;
font-size: var(--desktop-text);
font-weight: 400;
/* ; */
/* letter-spacing: 0em; */
margin-top: -5px;
text-align: left;
color: #878787;
}

.threeimages_first > .slick-slider > .slick-arrow{
    display: none !important;
}

.threeimages_first:hover> .slick-slider > .slick-arrow{
    display: block !important;
}

.threeimages_first > .slick-slider > .slick-arrow::before,
.threeimages_left > .slick-slider > .slick-arrow::before {
    color: #FF7703 !important;
}
.threeimages_first > .slick-slider > .slick-prev,
.threeimages_left > .slick-slider > .slick-prev {
    transform: translate(30px,5px);
    z-index: 1;
}  
.threeimages_first > .slick-slider > .slick-next,
.threeimages_left > .slick-slider > .slick-next {
    transform: translate(-60px);
    z-index: 1;
}

.threeimages_first > .slick-slider > .slick-arrow:before{
    font-size: var(--desktop-heading) !important;
  }

.threeimages_first > .slick-slider > .slick-next::before:hover,
.threeimages_left > .slick-slider > .slick-next::before:hover {
    color: #8a5b40 !important;
}

@media screen and (max-width:576px){
    .shopbyprice_brand_name{
        font-size: var(--mobile-text);
    }
}

@media screen and (max-width:992px){
    .shopbyprice_brand_name{
        font-size: var(--tablet-text);
    }
}
