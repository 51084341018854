.upperNavbarHamburger{
    cursor: pointer;
}

.mobileNavbarComponent{
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    top: 0;
    position: fixed;
    background: white;
    z-index: 11;
    transform: translateX(-100vw);
    transition: all 0.5s;
}

.mobileNavbarComponentVisible{
    transform: translateX(0vw);
}

.categoryDiv{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activeMobileNavbarDiv{
    font-weight: 600;
}

.mobileNavbarGroupWrapper{
    background: #ebebeb;
    z-index: 2;
}

.newHoverTriangle {
    position: absolute;
    right: -14px;
    height: 28px;
    transform: rotate(45deg);
    width: 28px;
    background: #ebebeb;
    top: 6px;
    z-index: 1;
}

.mobileNavbarUpperBorder{
    border-top: 1px solid #0000001f;
}

.newWhiteTriangle {
    position: absolute;
    right: -14px;
    height: 28px;
    transform: rotate(45deg);
    width: 28px;
    background: white;
    top: 6px;
    z-index: 1;
}

.mobileNavbarCategoryDiv{
    overflow: auto;
    max-height: 74vh;
}

.mobileNavbarGroupDiv{
    overflow: auto;
    max-height: 74vh;
}

.mobileNavbarCategoryDiv .MuiAccordion-root{
    border-radius: 0!important;
    box-shadow: none!important;
    background: #e9e9e9!important;
}

.mobileNavbarGroupDiv .MuiAccordion-root, .mobileNavbarCategoryDiv .MuiAccordionDetails-root{
    border-radius: 0!important;
    box-shadow: none!important;
    background: white!important;
}

.MuiAccordion-root-white{
    border-radius: 0!important;
    box-shadow: none!important;
    background: white!important;
}

.Mui-expanded{
    background: white!important;
    margin: 0!important;
}

.mobileNavbarOrangeText{
    color: var(--plypicker-primary);
}