.comp6_icons_main {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  margin-bottom: 50px;
  width: 100%;
}
.brandPartnerSlider_image:hover{
  transform: scale(0.95);
  transition: 0.4s all ease-in-out;
  object-fit: cover;
  cursor: pointer;
}

.iconsIcons{
  width: 100%;
  margin: 18px;
  height: 50px;
}

.iconsRow{
  height: 5em;
}

.comp6_icons_icon {
  margin-right: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .comp6_icons_main {
    justify-content: left;
  }
}

@media screen and (max-width: 992px) {
  /* .comp6_icons_main img {
    height: 30px;
    width: 115px;
  } */
  .brandPartnerSlider_image > img {
    width: 100% !important;
    /* padding: 10px; */
  }
  .sliderDiv{
    width: 40vw;
  }
}

@media screen and (max-width: 576px){
  .brandPartnerSlider_image img,
  .brandPartnerSlider_image {
    height: 300px;
    width: 210px;
  }
  .brandPartnerSlider_image > img {
    width: 100% !important;
    /* padding: 10px; */
  }
  .sliderDiv{
    width: auto;
  }
}
