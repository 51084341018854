.profile_main {
  display: flex;
  /* height: 86vh; */  
  overflow: hidden;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.css-1nl0624{
  padding-bottom: 0!important;
}
.wishlist_product_text_container{
  width: 40%;
}

.wishlistLoadingDiv{
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_profile {
  display: flex;
  overflow: hidden;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.profile_leftside {
  flex: 0.2;
  /* border-left: 1px solid #000000; */
  border-right: 1px solid #878787;
  height: auto;
  padding-bottom: 15px;
}

.wishlistPrice{
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.wishlist_product_text_container{
  width: 100%;
  margin-right: 10px;
}

.profile_renderside {
  display: flex;
  flex: 0.8;
  /* margin-top: 20px; */
  width: 100%;
}

.renderside_info {
  /* overflow-y: auto; */
  flex: 1;
  /* padding: 10px;
  padding-right: 30px;
  padding-left: 20px; */

  /* padding-right: 30px; */
}

.wishlist_hearticon{
  position: absolute;
  right: 10px;
  top: 9px;
}

.wishlist_hearticon > svg {
  fill: #FF7253;
}

.wishlist_hearticon > svg > path {
  stroke: #FF7253;
}
.wishlist_hearticon > svg:hover{
  cursor: pointer;
}

.renderside_info::-webkit-scrollbar {
  display: none;
}

.renderside_image {
  flex: 0.15;
  /* height: 85vh; */
  width: 300px;
}

.renderside_image img {
  height: 90%;
}

.wishlistpage_info {
  display: flex;
  position: relative;
  margin-bottom: 30px;
  /* max-width: 794px; */
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid #878787;
}

.wishlistpage_info:nth-child(1){
  margin-top: 30px!important;
}

/* .wishlistpage_info::after {
  content: "";
  position: absolute;
  background-color: #878787;
  width: 100%;
  height: 1px;
  bottom: -12px;
  left: 0;
} */

.wishlist_text {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.wishlist_text > .wishlist_product_text_container>.wishlist_product_heading:hover{
  color: #FF7703;
  cursor: pointer;
}

.wishlist_text > .wishlist_product_text_container>.wishlist_product_company:hover{
  color: #FF7703;
  cursor: pointer;
  text-transform: uppercase;
}

.wishlist_product_heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  ;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.wishlist_product_company {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  color: #878787;
  text-transform: uppercase;
}
.wishlist_product_amount {
  display: flex;
  margin-top: 25px;
  width: 100%;
  justify-content: space-between;
}

.wishlist_product_price {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  ;
  color: #8A5B40;
  margin: 0;
}

.wishlist_product_add {
  background: #FF7703;
  border: 1px solid #FF7703;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  color: white;
  transition: all 0.1s;
}
.wishlist_product_add:hover{
  /* transform: translateY(-2px); */
  cursor: pointer;
  color: white;
}

.wishlist_img img {
  height: 100%;
  width: 100%;
}

.wishlist_img {
  height: 120px;
  width: 120px;
  margin: 20px;
  margin-right: 0;
}

/* .wishlist_hearticon {
  position: absolute;
  right: 40px;
  top: 0;
} */
@media screen and (max-width: 1200px) {
  .renderside_info {
    padding-right: 10px;
  }

  .renderside_image {
    flex: 0.1;
  }

  .renderside_info {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .wishlist_product_amount {
    justify-content: flex-start;
  }
  .wishlist_product_text_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .wishlist_product_amount {
    margin-top: 0;
  }
  .wishlist_img {
    margin-top: 6px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
  }

  .wishlist_text {
    margin-top: 6px;
    margin-left: 18px;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .wishlistpage_info:nth-child(1) {
    margin-top: 12px!important;
  }

  .wishlistpage_info {
    margin-top: 6px;
    margin-bottom: 6px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #878787;
  }

  .renderside_image {
    display: none;
  }

  .renderside_info {
    flex: 1;
  }

  .renderside_info {
    flex: 1;
    padding-right: 0px !important;
  }
  .profile_leftside {
    flex: 0.4;
  }

  .profile_renderside {
    flex: 0.7;
  }
  .address_first{
    width: 15%;
  }
}

/* @media screen and (max-width: 768px) {
  .wishlist_product_heading {
    font-size: 18px;
    ;
  }

  .wishlist_product_company,
  .wishlist_product_price,
  .wishlist_product_add {
    font-size: 16px;
    ;
  }
  .address_first{
    width: 22%;
  }
} */
/* @media screen and (max-width: 992px) {
  .wishlist_product_price{
    color: black;
    font-weight: 500;
  }
} */
@media screen and (min-width: 576px) {
  .hamburger {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .wishlist_hearticon {
    top: 19px;
  }
  .wishlist_product_price{
    margin-top: 23px;
  }
  .wishlist_product_heading{
    margin-top: 17px;
  }
  .wishlist_product_text_container{
    display: block;
  }
  .wishlist_product_amount {
    display: block;
  }
  .wishlistpage_info {
    margin-left: 0!important;
  }
  .profile_leftside {
    display: none;
  }
  .profile_renderside {
    flex: 1;
  }

  .renderside_info {
    padding-left: 0 !important;
  }
  .wishlist_hearticon {
    right: 10px;
  }
  /* .wishlist_hearticon {
    position: absolute;
    right: 20px;
    top: -4px;
  } */
  .wishlist_product_heading {
    font-size: var(--mobile-subheading);
    ;
  }

  .wishlist_product_company,
  .wishlist_product_price,
  .wishlist_product_add {
    font-size: var(--mobile-text);
    
  }

  .wishlist_img img {
    height: 90%;
    width: 80%;
  }

  .wishlist_img {
    flex: 0.3;
  }

  .wishlist_text {
    flex: 0.7;
    margin-left: 0;
  }
}
