@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Trirong&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Trirong:ital,wght@1,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

:root {
    --desktop-heading: 44px;
    --desktop-subheading: 28px;
    --desktop-text: 16px;
    --desktop-subtext: 12px;

    --tablet-heading: 32px;
    --tablet-subheading: 24px;
    --tablet-text: 14px;
    --tablet-subtext: 10px;

    --mobile-heading: 20px;
    --mobile-subheading: 16px;
    --mobile-text: 12px;
    --mobile-subtext: 8px;

    --plypicker-primary: #FF7703;

    --new-desktop-heading: 44px;
    --new-desktop-subheading: 22px;
    --new-desktop-text: 16px;

    --new-tablet-heading: 32px;
    --new-tablet-subheading: 22px;
    --new-tablet-text: 15px;

    --new-mobile-heading: 28px;
    --new-mobile-subheading: 20px;
    --new-mobile-text: 14px;
}

.newHeading {
    font-size: var(--new-desktop-heading);
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
}

.newSubheading {
    font-size: var(--new-desktop-subheading);
    font-family: 'Noto Sans', sans-serif;
}

.newText {
    font-size: var(--new-desktop-text);
    font-family: 'Noto Sans', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins';
    word-spacing: 3px;
}

html {
    font-family: 'poppins';
    word-spacing: 3px;
}

.text-trilong {
    font-family: 'Trirong', serif;
}

.slick-slider {
    overflow-x: hidden;
}

.modal-content {
    border-radius: 0 !important;
    border: none;
}

.btn-close:focus-visible {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn-close:focus-within {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn-close:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.plypickerInputDropdown {
    border: 1px solid #FF7703;
    cursor: default;
    text-transform: capitalize;
    -webkit-user-select: none;
    user-select: none;
    color: black;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.plypickerInputDropdown:focus-visible {
    outline: none !important;
}

.plypickerInputDropdown:focus {
    outline: none !important;
}

.plypickerInputDropdown:focus-within {
    outline: none !important;
}

button {
    -webkit-appearance: none;
    border-radius: none;
}

input {
    border-radius: 0;
}

.subtext {
    font-size: var(--desktop-subtext);
}

.subheading {
    font-size: var(--desktop-subheading);
}

.plypicker-heading-text {
    font-size: var(--desktop-heading);
}

@media screen and (max-width: 992px) {
    .subheading {
        font-size: var(--tablet-subheading);
    }

    .plypicker-heading-text {
        font-size: var(--tablet-heading);
    }

    .newHeading {
        font-size: var(--new-tablet-heading);
    }

    .newSubheading {
        font-size: var(--new-tablet-subheading);
    }

    .newText {
        font-size: var(--new-tablet-text);
    }
}

@media screen and (max-width: 576px) {
    .subheading {
        font-size: var(--mobile-subheading);
    }

    .plypicker-heading-text {
        font-size: var(--mobile-heading);
    }

    .newHeading {
        font-size: var(--new-mobile-heading);
    }

    .newSubheading {
        font-size: var(--new-mobile-subheading);
    }

    .newText {
        font-size: var(--new-mobile-text);
    }
}

* {
    scrollbar-width: auto;
    scrollbar-color: #8f8f8f #ffffff;
}

*::first-letter {
    text-transform: capitalize;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #8f8f8f9f;
    border-radius: 10px;
    border: 3px solid #ffffff;
}