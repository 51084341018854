.filterContainer{
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.filterContainer input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.filterContainer .checkmark{
    position: absolute;
    top: -25px;
    left: -25px;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #C4C4C4;
}

.filterContainer:hover input ~ .checkmark {
    background-color: transparent;
}

.filterContainer input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid #ff7253;
}

.filterContainer .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.filterDiv .discountPercentDescription{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filterContainer input:checked ~ .checkmark:after {
    display: block;
}

.filterContainer .checkmark:after {
    background-image: url(../img/Filter/filterCheckmark.png);
    width: 18px;
    height: 14px;
    left: 3px;
    top: 5px;
    background-repeat: no-repeat;
}