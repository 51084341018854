.servicesListPopup {
  width: 75vw;
  max-width: 75vw;
  min-height: 80vh;
  max-height: 95vh;
  height: 95vh;
  overflow: auto;
  top: 5vh;
  left: 50%;
  position: fixed;
  background: white;
  z-index: 11;
  transform: translate(-50%, 100vh);
  transition: all 0.75s;
}

.servicesListPopupVisible {
  transform: translate(-50%, 0vh) !important;
}

.servicePopupBackground {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0;
  z-index: 10;
  background: #00000000;
  transition: all 0.5s;
}

.servicePopupBackgroundVisible {
  width: 100vw;
  height: 100vh;
  background: #0000002c;
}

.serviceCartNotifPopup {
  width: 400px;
  max-width: 90vw;
  max-height: 48px;
  height: 40px;
  overflow: auto;
  bottom: 5vh;
  left: 50%;
  position: fixed;
  background: white;
  outline: 1px solid var(--plypicker-primary);
  z-index: 9;
  transform: translate(-50%, 100px);
  transition: all 0.5s;
}

.serviceCartNotifPopupVisible {
  transform: translate(-50%, 0);
}

.modal.fade {
  padding: 0 !important;
}

.changeAddressButton_services {
  background-color: transparent;
  border: 1px solid #ff7703;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: black;
}

.cartside_total_checkout_services {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 4px 10px;
  cursor: pointer;
  color: #ffffff;
  background: #ff7703;
  margin-top: 20px;
  border: none;
  outline: none;
}

.ServiceCategoryBigImage {
  object-fit: cover;
  width: 90%;
  max-height: 620px;
}

.ServiceCategoryHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
}

.ServiceCategoryDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.ServiceHeading {
  font-family: "Trirong";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
}

.serv-loading-shimmer {
  display: flex;
  flex-direction: row;
  /* border: 1px solid #ddd; */
  padding: 10px;
  /* margin-bottom: 2em; */
  overflow-x: hidden;
  overflow-y: hidden;

}

.serv-shimmer-image {
  width: 30vw;
  height: 30vh;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.serv-shimmer-line {
  margin-top: 10px;
  height: 12px;
  width: 500px;
  margin-bottom: 5px;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.serv-shimmer-details {
  flex: 1;
  padding-left: 10px;
  margin-top: 2%;
}

.serv-loading-shimmer1 {
  display: flex;
  flex-direction: row;
  /* border: 1px solid #ddd; */
  padding: 10px;
  /* margin-bottom: 2em; */
  overflow-x: hidden;
}

.serv-shimmer-image1 {
  width: 8vw;
  height: 13vh;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.serv-shimmer-line1 {
 
  height: 10px;
  width: 400px;
  border-radius: 40px;
  margin-bottom: 5px;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.serv-shimmer-line2 {
 
  height: 10px;
  width: 100px;
  border-radius: 40px;
  margin-bottom: 5px;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.serv-shimmer-details1 {
  flex: 1;
  padding-left: 10px;
  margin-top: 2%;
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .servicesListPopup {
    width: 95vw;
    max-width: 95vw;
  }
  .serv-loading-shimmer {
    display: flex;
    flex-direction: row;
    /* border: 1px solid #ddd; */
    padding: 10px;
    /* margin-bottom: 2em; */
  }

  .serv-shimmer-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding-left: 10px;
    /* margin-top: 2%; */
  }
  .serv-shimmer-image {
    width: 40vw;
    height: 25vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line {
    /* margin-top: 30px; */
    height: 20px;
    width: 400px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-image1 {
    width: 170px;
    height: 13vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line1 {
 
    height: 10px;
    width: 200px;
    border-radius: 40px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line2 {
   
    height: 10px;
    width: 100px;
    border-radius: 40px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .shimmermaindiv{
    width: 100% !important;
    align-items: center !important;
  }
}

@media screen and (max-width: 576px) {

  .serv-shimmer-image {
    width: 150px;
    height: 25vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line {
    /* margin-top: 30px; */
    height: 20px;
    width: 200px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }

  .servicesListPopup {
    width: 95vw;
    max-width: 100vw;
  }

  .serviceCartNotifPopup {
    bottom: 12vh;
  }

  .pagination-button {
    padding: 5px 10px;
    margin: 2px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    height: 45px;
    width: 45px;

    /* Additional styles for page numbers */
    color: #333;
  }
  .shimmermaindiv{
    width: 100% !important;
    align-items: center !important;
  }
  .serv-shimmer-image {
    width: 40vw;
    height: 20vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line {
    margin-top: 10px;
    height: 12px;
    width: 150px;
    /* margin-bottom: 5px; */
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-image1 {
    width: 170px;
    height: 13vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line1 {
 
    height: 10px;
    width: 100px;
    border-radius: 40px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  .serv-shimmer-line2 {
   
    height: 10px;
    width: 70px;
    border-radius: 40px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
}

.pagination-button {
  padding: 5px 10px;
  margin: 2px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  height: 45px;
  width: 45px;

  /* Additional styles for page numbers */
  color: #333;
}

/* Active button style */
.pagination-button.active {
  background-color: #8a5b40;
  color: #fff;
  border: 1px solid #8a5b40;
}
