.newLowerNavbarOuterContainerr{
    width: 100%;
    padding-top: 7px;
    position: relative;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 59%, rgba(255,255,255,0) 100%);
}

.newLowerNavbarContainerr {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    width: fit-content;
    padding: 0;
    position: relative;
    cursor: pointer;
    max-width: 80%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    background: transparent!important;
}

.newLowerNavbarContainerr::-webkit-scrollbar {
    width: 10px;
    height: 4px;
}
  /* Track */
.newLowerNavbarContainerr::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(181, 181, 181); 
    border-radius: 6px;
    background: #80808071;
}

  /* Handle */
.newLowerNavbarContainerr::-webkit-scrollbar-thumb {
    background: #fd7e14; 
    border-radius: 10px;
    border: none;
}

  /* Handle on hover */
.newLowerNavbarContainerr::-webkit-scrollbar-thumb:hover {
    background: #c4671b; 
}

.newLowerNavbarContainerr .categoryNameBullet {
    list-style-type: none;
    padding-left: 25px;
    padding-right: 25px;
    white-space: nowrap;
    position: relative;
}

.newLowerNavbarDropdown{
    // display: none;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 0s, opacity 0.333s linear;
}

.newLowerNavbarDropdown:hover{
    display: block!important;
    position: absolute;
    top: 100%;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;
    background: white;
    box-shadow: 0px 100vh 0px 100vh rgb(0 0 0 / 15%);
    visibility: visible;
    opacity: 1;
    height: auto;
}

.newLowerNavbarHoverStyle {
    background: white;
    &:focus ~ .newLowerNavbarDropdown,
    &:hover ~ .newLowerNavbarDropdown {
        display: block!important;
        position: absolute;
        top: 100%;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 5;
        background: white;
        box-shadow: 0px 100vh 0px 100vh rgb(0 0 0 / 15%);
        visibility: visible;
        opacity: 1;
        height: auto;
    }
}

.newLowerNavbarOuterContainerr:not(:focus-within):not(:hover){
    .newLowerNavbarHoverStyle {
        background-color: white;
    }
}

.newLowerNavbarCategoryName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-text);
    text-decoration: none;
    color: #2E2E2E;
}

.newLowerNavbarCategoryName:after{
    content: "⠀";
    background: url("./arrow.png") no-repeat;
    position: absolute;
    top: 38%;
    right: 8px;
}

.newLowerNavbarCategoryName:hover:after{
    right: -17px;
}

.newLowerNavbarCategoryName:hover:before{
    content: "";
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: #f16512;
    left: 0;
    bottom: 0;
}

.newLowerNavbarCategoryName:hover {
    color: #2E2E2E;
    position: relative;
}

.subCategoryLink:hover, .newLowerNavbarGroupLink:hover{
    color: #000000; 
}

.subCategoryLink,
.newLowerNavbarGroupLink {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: var(--desktop-text);
    color: #000000;
    text-decoration: none;
    width: 100%;
}

.subCategoryLinkWrapper {
    position: relative;
}

.newHoverTriangle {
    position: absolute;
    right: -14px;
    height: 28px;
    transform: rotate(45deg);
    width: 28px;
    background: #ebebeb;
    top: 6px;
    z-index: 1;
}

.newGroupDivWrapper {
    background: #ebebeb;
    z-index: 2;
}

.newLowerNavbarGroupLink:hover {
    font-weight: 500;
}

.lowerNavbarPopularLinkWrapper {
    margin-bottom: 5px;
    margin-top: 5px;
}

.lowerNavbarPopularLink {
    text-decoration: none;
    color: inherit;
    margin-bottom: 10px !important;
}

.newSubGroupDivWrapper{
    background-color: white;
}

.lowerNavbarPopularLink p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newLowerNavbarBrandsWithSubgroups{
    border-left: 1px solid #0000002e;
}

.lowerNavbarPopularLink:hover {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}

@media (max-width: 992px) {
    .newLowerNavbarContainerr {
        width: 100%;
        max-width: 100%;
    }

    .newLowerNavbarHoverStyle {
        background: white;
        &:focus ~ &,
        &:hover ~ & {
            display: block!important;
            position: absolute;
            top: 100%;
            width: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 5;
            background: white;
            box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
            visibility: visible;
            opacity: 1;
            height: auto;
        }
    }

    .newLowerNavbarDropdown:hover {
        width: 100%;
    }

    .newLowerNavbarContainerr .categoryNameBullet:first-child{
        padding-left: 15px;
    }
    .newLowerNavbarContainerr .categoryNameBullet:last-child{
        padding-right: 20px;
    }
}