/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;
  --nav-width: 68px;

  /*===== Font and typography =====*/
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 19px;

  /*===== z index =====*/
  --z-fixed: 100;
}
/*===== NAV =====*/
.backbtn{
  margin: 36px 23px 52px 15px;
}

.nav {
  /* height: 90vh; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow: hidden;
  font-family: "Nunito", sans-serif;
}

.sidebarProfilePageContainer{
  max-width: 1100px;
  margin: auto;
}

.sidebarRightBorder{
  border-right: 1px solid #878787;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarAccountSettings{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  align-items: center;
  color: #878787;
}

.nav__logo,
.nav__link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 5px 0 5px 0;
  text-decoration: none;
}

.profilePicDiv{
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid #878787
}
.nav__link:hover{
  cursor: pointer;
}
.nav__link__outer{
  display:flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  margin: 25px 20px;
  padding: 5px 0px;
  /* margin-top: 35px */
}

.LogoutButton:hover{
  /* border: 1px solid #FF7703;
  background-color: #FF7703 !important;
  box-shadow: 6px 7px 10px #fd7e14;
  transform: translateY(-5px);
  cursor: pointer; */
}

.LogoutButton:hover > a{
  /* color: #ffffff; */
}

.nav__link__outer > a{
  padding: 0px;
}

.nav__logo {
  margin-bottom: 2rem;
  margin-left: -6px;
}

.nav__logo:hover {
  text-decoration: none;
}

.nav__logo-icon {
  width: 30px;
  height: 30px;
}

.sideBarLogoutButton{
  margin-top: 18px;
  width: 91%;
}

.sideBarLogoutButton:hover{
  color: #000000!important;
}

.nav__logo-name {
  color: black;
  font-weight: 700;
  font-size: 19px;
}

.nav__link {
  position: relative;
  color: black;
  transition: 0.3s;
  padding-left: 1px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
}

.nav__link:hover {
  color: #FF7703;
}

.nav__icon {
  font-size: 1.25rem;
}

/*Active links*/
.active-nav {
  /* background-color: #EBEBEB; */
  color: #FF7703;
  font-weight: bold;
  border-radius: 5px;
  /* margin-left: 10px;
  padding-left: 12px; */
}

.active-nav:hover{
  text-decoration: none;
  color: #FF7703;
}

.nav__list {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  margin-left: -10px;
  margin-right: 5px;
}

.sidebar_logout {
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profile__sidebar{
  /* overflow-y: scroll;
  height: 40vh; */
}
/* width */
.profile__sidebar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.profile__sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(181, 181, 181); 
  border-radius: 6px;
}
 
/* Handle */
.profile__sidebar::-webkit-scrollbar-thumb {
  background: #fd7e14; 
  border-radius: 10px;
}

/* Handle on hover */
.profile__sidebar::-webkit-scrollbar-thumb:hover {
  background: #c4671b; 
}

@media screen and (max-width: 576px){
    .sidebarAccountSettings{
      font-size: var(--mobile-text);
    }
}

@media screen and (max-width: 992px){
  .sidebarAccountSettings{
    font-size: var(--tablet-text);
  }
}