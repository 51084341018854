.threeimages_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slickImages{
    height: 380px;
    object-fit: cover;
}

.firstHomepageContainer{
  margin-top: 20px;
}

.threeimages_main img {
  width: 100%!important;
  height: 100%!important;
}

.threeimages_first,
.threeimages_second {
  width: 100%;
}

.threeimages_second {
  display: flex;
  margin-top: 10px;
}

.threeimages_left {
  margin-right: 10px;
  flex: 0.7;
  overflow: hidden;      /*ref: https://github.com/kenwheeler/slick/issues/2378#issuecomment-311054148 */
}

.threeimage_right {
  flex: 0.3;
  padding-bottom: 7px;
}

@media screen and (max-width: 992px) {
  .threeimage_container {
    max-width: 900px !important;
    /* margin: 0 !important; */
  }
}

@media (min-width: 1700px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1830px;
}
}