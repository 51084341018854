.footer_main_container {
  background: #ebebeb;
  padding: 60px 0;
}

.footer_inside_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer_first_two_columns,
.footer_last_two_columns {
  display: flex;
  justify-content: space-around;
  flex: 0.5;
}

.footer_column_heading {
  font-family: "Trirong", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: var(--desktop-subheading);
  
  color: #000000;
  margin-bottom: 15px;
}

.footer_contents {
  font-family: "Trirong", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  
  color: #878787;
  list-style: none;
  padding-left: 0;
}

.footer_column ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer_column ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.footer_contents li a {
  text-decoration: none;
  color: #878787;
}

.footer_contents li img {
  margin-top: 15px;
}

.footer_images_column {
  position: relative;
}

.footer_logo {
  position: absolute;
  left: 10px;
  top: 9px;
}

.footer_text {
  position: absolute;
  left: 60px;
  top: 7px;
}

@media screen and (max-width: 992px) {
  .footer_inside_container {
    flex-direction: column;
  }

  .footer_last_two_columns {
    margin-top: 20px;
  }

  .footer_first_column {
    margin-left: -15px;
  }
  
  .footer_column_heading{
    font-size: var(--tablet-subheading);
  }

  .footer_contents{
    font-size: var(--tablet-text);
  }
}

@media screen and (max-width: 576px) {
  .footer_main_container {
    padding: 10px 0;
  }

  .footer_first_two_columns,
  .footer_last_two_columns {
    flex-direction: column;
  }

  .footer_first_column,
  .footer_second_column,
  .footer_third_column,
  .footer_fourth_column {
    margin-left: 0px;
    margin-top: 20px;
  }

  .footer_column_heading {
    font-size: 18px;
    ;
  }

  .footer_contents {
    font-size: 16px;
    
  }
}
