/* Three SCreen shimmer*/

.threeSliderShimmer {
  height: 100vh;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: threeSlider-shimmer-animation 3s infinite;
}

@keyframes threeSlider-shimmer-animation {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

/*Banner*/

.bannerWrapper {
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  
  animation: price-shimmer-animation 3s infinite;
 height: 20vh;
 width: 96%;
 margin-left: 2%;
}

/*Topbrand*/
.topBrandWrapper {
  display: flex;
  flex-direction: row;
  background-color: rgb(248, 248, 248);
}

.topBrandShimmer {
  height: 40vh;
  width: 50vh;
  margin: 2%;
  background-color: white;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
 animation: topBrand-shimmer-animation 2s infinite;
}

/*Shopbyprice shimmer*/
.priceShimmerwrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(248, 248, 248);
  /* background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  animation: price-shimmer-animation 3s infinite; */
}

.priceShimmer {
  height: 40vh;
  width: 100vh;
  margin: 2%;
  background-color: white;
  /* background-color: rgb(241, 241, 241); */
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  animation: price-shimmer-animation 3s infinite;
}
@keyframes price-shimmer-animation {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}



/*recently viewed Shimmer*/
.recentlyViewedShimmerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2%;

  height: 48vh;
  background-color: rgb(248, 248, 248);
  /* background-color: rgb(241, 241, 241);*/
 
}
.recentlyViewedShimmer{
  height: 40vh;
  width: 100vh;
  margin: 2%;
  background-color: white;
background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);

  animation: recently-shimmer-animation 3s infinite;
}



@keyframes recently-shimmer-animation {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}


/*Hire craftman shimmer*/
.hireShimmerWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(248, 248, 248);
  /* background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  animation: price-shimmer-animation 3s infinite; */
}
.hireShimmer{
  height: 40vh;
  width: 100vh;
  margin: 2%;
  background-color: white;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);  
  animation: recently-shimmer-animation 3s infinite;
}




/*our service shimmer*/
.ourServiceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: rgb(241, 241, 241); */
  background-color: rgb(247, 247, 247);
 
  
}
.ourServiceShimmer{
  height: 40vh;
  width: 100vh;
  margin: 2%;
  background-color: white;
background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  animation: price-shimmer-animation 3s infinite;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

@media only screen and (max-width: 550px) {
  .hdiv {
    width: 74vw;
  }
  .shine {
    width: 60vw;
  }
  .priceShimmer {
    height: 35vh;
  }
  .topBrandShimmer {
    height: 35vh;
  }
  .recentlyViewdShimmer {
    height: 35vh;
  }
  .threeSliderShimmer {
    height: 20vh;
  }
}
@media only screen and (min-width: 551px) and (max-width: 1024px) {
  .threeSliderShimmer {
    height: 20vh;
  }
  .bannerWrapper {
    height:10vh
  }
}



