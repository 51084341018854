
  
/* .abc{
  display: flex; */
  /* justify-content: center; */
  /* background-color: peachpuff;
  
} */

/* .modalData{
  display: flex;
  background-color: orange;
  align-items: center;
  align-content: center;
  justify-content: center;
  
} */
/* .modalFooter{
margin-top: 0px;
} */
.homeModalHeading{
  display: flex;
  align-items: center;
  justify-content: center;
}


.homeModalWrapper{
  display: flex;
  flex-direction: row;
  /* height: 23vh; */

}
.userAddresses{
  display: flex;
  flex-direction: column;
  justify-content: center;
 
}
.homeModalLeft{
  display: flex;
  flex-direction: column;
  justify-content: start !important;
  margin-right: 2%;
  width: 100%;
  
  overflow: auto;
 
}
.homeModalleft::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.homeModalRight{
 display: flex;
 flex-direction: column;
  width: 100%;
}
.homeModalBorder{
  /* border: 2px solid #b8b8b8; */
  padding: 2%;
  margin-top: 1%;
}

.cityImages{
  object-fit: cover;
  height: 100px;
  width: 80px;
}
