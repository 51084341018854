.shopbycategory_main {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  /* align-items: center; */
}

.shopbycategory_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.shopbycategory_first_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: var(--desktop-subheading);
  /* identical to box height */
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: #8a5b40;
  position: relative;
  justify-content: center;
  /* width: 200px; */
}

.shopbycategory_first_heading::before {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 50%;
  height: 1px;
  top: 15px;
  left: 110px;
}

.shopbycategory_first_heading::after {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 50%;
  height: 1px;
  top: 15px;
  right: 110px;
}

.shopbycategory_second_heading {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 700;
  font-size: var(--desktop-heading);
  display: flex;
  align-items: center;
  text-align: center;
  /* align-self: center; */
  color: #000000;
  margin-top: 20px;
  text-transform: uppercase;
}

.shopbycategory_boxes {
  /* background-color: green; */
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: center;
  overflow-x: overlay;
  padding-bottom: 15px;
  /* white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden; */
}

.shopbycategory_boxes::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
.shopbycategory_boxes::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(181, 181, 181); 
  border-radius: 6px;
}
 
/* Handle */
.shopbycategory_boxes::-webkit-scrollbar-thumb {
  background: #fd7e14; 
  border-radius: 10px;
}

/* Handle on hover */
.shopbycategory_boxes::-webkit-scrollbar-thumb:hover {
  background: #c4671b; 
}

.shopbycategory_boxes_outer_outer:hover{
  transform: scale(1.1);
  transition: 0.2s all ease-in-out;
  object-fit: cover;
}
.shopbycategory_boxes_outer_outer {
  margin-top: 15px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
}
.shopbycategory_boxes_outer_outer > a:link { text-decoration: none; }
.shopbycategory_boxes_outer_outer > a:visited { text-decoration: none; }
.shopbycategory_boxes_outer_outer > a:hover { text-decoration: none; }
.shopbycategory_boxes_outer_outer > a:active { text-decoration: none; }

.shopbycategory_boxes .shopbycategory_boxes_outer_outer:first-child {
  margin-left: auto;
}

.shopbycategory_boxes .shopbycategory_boxes_outer_outer:last-child {
  margin-right: auto;
}

.shopbycategory_boxes_outer {
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  padding: 2px;
}

.shopbycategory_boxes_inner {
  background: #ffffff;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
}

.shopbycategory_boxes_inner_text {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-text);
  color: #000000;
}

@media screen and (max-width: 992px) {
  .shopbycategory_first_heading {
    font-size: var(--tablet-subheading);
  }

  .shopbycategory_second_heading {
    font-size: var(--tablet-heading);
    margin-top: 10px;
  }

  .shopbycategory_first_heading::before {
    top: 12px;
    left: 90px;
  }

  .shopbycategory_first_heading::after {
    top: 12px;
    right: 90px;
  }

  .shopbycategory_boxes_inner {
    height: 100px;
    width: 110px;
  }

  .shopbycategry_boxes_inner_image img {
    height: 60px;
    width: 60px;
  }
  .shopbycategory_boxes_inner_text{
    font-size: var(--tablet-text);
  }
}

@media screen and (max-width: 576px) {
  .shopbycategory_first_heading {
    font-size: var(--mobile-subheading);
  }

  .shopbycategory_second_heading {
    font-size: var(--mobile-heading);
    ;
    margin-top: 10px;
  }

  .shopbycategory_first_heading::before {
    top: 10px;
    left: 70px;
  }

  .shopbycategory_first_heading::after {
    top: 10px;
    right: 70px;
  }

  .shopbycategory_boxes_inner_text {
    font-size: var(--mobile-text);
  }
}

@media screen and (min-width: 1750px){
  .shopbycategory_second_heading{
    font-size: 50px;
  }
}
