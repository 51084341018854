.bottomAppBar {
    z-index: 999;
}

.bottomAppBar>div>a{
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color: #FF7703 !important;
}

.bottomAppBar>div>a>svg{
    color: #000000 !important;
}

.css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected{
    color: #000000 !important;
}