.agreementDiv{
    max-height: 525px;
    overflow-y: scroll;
}

.serviceCartDateTimeDiv .MuiSvgIcon-root{
    color: #8A5B40
}

@media screen and (max-width: 576px) {
    .agreementDiv{
        max-height: 425px;
    }
  }

  /* LoadingShimmer.css */
.loading-shimmer {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 2em;
  }
  
  .shimmer-image {
    width: 100px;
    height: 100px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  
  .shimmer-details {
    flex: 1;
    padding-left: 10px;
    margin-top: 2%;
  }
  
  .shimmer-line {
    height: 16px;
    width: 30%;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  
  @keyframes shimmer-animation {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  