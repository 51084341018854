.searchbar_search {
    padding: 5px;
    padding-left: 10px;
    border: none;
    outline: none;
    border: 2px solid #F2F2F2;
    background-color: #F5F5F5;
    border-radius: 2px;
    width: 100%;
}

.searchbar_search::placeholder{
    color: #878787;
    opacity: 0.75;
}

.searchbar_output {
    padding: 12px !important;
    transition: 0.2s;
    margin-left: 4px;
    color: #878787;
}

.searchbar_output:hover {
    background-color: gray;
    color: white;
}
.history_output{
    padding: 12px !important;
    transition: 0.2s;
    margin-left: 4px;
    color: #878787;
}
.history_output:hover{
    background-color: gray;
    color: white;
}
.hoverMe:hover{
    cursor: pointer;
}