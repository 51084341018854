.hpComponent2Image{
    width: 70%!important;
    max-height: 45%;
    padding: 0!important;
    height: 45%!important;
}

.hpComponent2ImgWrapper{
    width: 75%!important;
    max-width: 123px;
    border-radius: 8px;
    background: #EBEBEB;
    aspect-ratio: 1/1;
}

.hpComponent2DivWrapper{
    cursor: pointer;
}

.hpComponent2BannerWrapper{
    height: 80%;
    margin-top: auto;
    margin-bottom: auto;
}

.truncate{
    width: 1005;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.hpComponent2Part1Parent{
    min-height: 40vh;
}

.hpComponent2FilledButton{
    border-radius: 3px;
    border: 1px solid #000;
    background: #232323;
    color:  #FFF;
    min-width: fit-content;
    width: 12.5em;
    max-width: 50%;
    padding: 0.75em;
    
}

.hpComponent2OutlinedButton{
    border-radius: 3px;
    border: 1px solid #000;
    background: #FFF;
    color: #232323;
    min-width: fit-content;
    width: 12.5em;
    max-width: 50%;
    padding: 0.75em;
}

/*shimmer Css*/
.comp2-loading-shimmer {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 2em;
  }

  .comp2-shimmer-image {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
   
  .comp2-shimmer-details {
    flex: 1;
    padding-left: 10px;
    margin-top: 2%;
  }
  .comp2-shimmer-line {
    height: 16px;
    width: 30%;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }


@media (max-width: 992px) {
    .hpComponent2Part1Parent{
        height: auto;
    }
    .hpComponent2OutlinedButton, .hpComponent2FilledButton{
        width: 40%;
    }
    .comp2-shimmer-image {
        width: 100vw;
        height: 70vh;
        background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
        background-size: 800px 100%;
        animation: shimmer-animation 1.5s infinite;
      }
}