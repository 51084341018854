.main-div{
display: flex;
justify-content: space-between;
flex-wrap: wrap;
margin: 20px;
}

.inner-div{
    flex: 1; 
    margin-right: 10px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; 
}

.inner-div img{
    width: calc(50% - 5px);
}
  /*Shimmer css*/

.comp4-loading-shimmer {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 2em;
  }

  .comp4-shimmer-image {
    width: 220px;
    height: 180px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
   
  .comp4-shimmer-details {
    flex: 1;
    padding-left: 10px;
    margin-top: 2%;
  }
  .comp4-shimmer-line {
    height: 16px;
    width: 240px;
    margin-bottom: 5px;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }



@media (max-width: 992px) {
    .inner-div{
        flex-basis : calc(50% - 5px);
    }
}

@media (max-width: 576px) {
    .comp4-shimmer-line {
        height: 7px;
        width: 45px;
        margin-bottom: 5px;
        background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
        background-size: 800px 100%;
        animation: shimmer-animation 1.5s infinite;
      }
      .comp4-shimmer-image {
        width: 45px;
        height: 45px;
        background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
        background-size: 800px 100%;
        animation: shimmer-animation 1.5s infinite;
      }
}

@media (min-width: 576px) and (max-width: 992px) {
    .comp4-shimmer-line {
        height: 15px;
        width: 120px;
        margin-bottom: 5px;
        background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
        background-size: 800px 100%;
        animation: shimmer-animation 1.5s infinite;
      }
      .comp4-shimmer-image {
        width: 110px;
        height: 100px;
        background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
        background-size: 800px 100%;
        animation: shimmer-animation 1.5s infinite;
      }
}

