.counterButton {
  width: 28px;
  border: 0.5px solid #d9d9d9;
  background-color: transparent;
  color: black;
}

.counterButtonDisable {
  width: 28px;
}

.counterButtonDisable:disabled,
.counterButtonDisable[disabled] {
  border: 0.5px solid #d9d9d9;
  background-color: transparent;
  color: black;
}

.productCounterCenter {
}

.counterValue {
  margin: 10px;
}

.cartCounterValueInput {
  width: 40px;
  border-radius: 0px;
  border: 1px solid #575757;
  padding-left: 2px;
  padding-right: 2px;
}

.cartCounterValueInput:focus-visible {
  border: 1px solid black;
  outline: none;
}

.cartCounterValueInput::-webkit-outer-spin-button,
.cartCounterValueInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.servicePagination {
  display: flex;
  justify-content: center;
  margin-top: 4%;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #8a5b40 !important;
  color:"white" !important;
  border-radius: none !important;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: white !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: rgba(0, 0, 0, 0.08);
}
.servicePagination button {
    background: #fff;
    border: 1px solid #666;
    padding: 10px 15px;
    /* border-radius: 50%; */
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
    color: black;
    border-radius: 0;
}

.servicePagination .Mui-selected{
    border: 0;
}

@media screen and (max-width: 576px) {
  .cartCounterValueInput {
    width: 24px !important;
    /* border: yellow; */
  }
  .counterButtonDisable {
    width: 25px !important;
  }
  .counterButton {
    width: 25px !important;
  }
  .servicePagination {
    display: flex;
    justify-content: center;
    margin-top: 13%;
    border-radius: 0px !important;
  }
  .servicePagination button {
    height: 4vh;
    width: 5vw !important;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: white !important;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
    .servicePagination button {
        height: 5vh;
        width: 5vw !important;
        margin-top: 2% !important;
      }
      .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        color: white !important;
      }
}
