.mainAddressBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0.8 1;
}

.headingAddress {
    width: 100%;
    border-bottom: 2px solid #000000 !important;
}

.headingADeleteAddress{
    width: 100%;
    border-bottom: none !important;   
}

.AddressNameModal {
    text-transform: uppercase;
}

.AddressRadio {
    margin-left: 50px;
}

.addressMobileNumberInputPrefix{
    margin-right: 10px;
}

.addressMobileNumberInputWrapper{
    flex: 1;
}

.address_type>span {
    color: #878787 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: var(--desktop-text);
}

.InsideModalSpace {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between;
}

.InsideModalSpace>span>input {
    width: 85%;
}

.AdressModalSubmit {
    display: flex;
    width: 100%;
}

.addUpdateButton {
    background: #FF7253!important;
    border: none!important;
    color: white!important;
    padding: 5px 30px!important;
    cursor: pointer!important;
    height: fit-content;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-right: 1rem;
    height: 35px;
    min-height: fit-content;
}

.CloseaddUpdateButton{
    background: transparent!important;
    border: 1px solid #FF7253!important;
    padding: 5px 30px!important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: black!important;
    height: 35px;
    min-height: fit-content;
    white-space: nowrap;
}


.AdressModalSubmit>button {
}

.inputLabels {
    color: #878787 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: var(--desktop-text);
}

.address_change_popup {
    padding: 0px;
    /* background: #e5e5e5; */
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}

.addAddress {
    text-transform: uppercase;
    font-family: "Poppins";
    font-weight: 400;
    color: #FF7253;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px !important;
    background: #ffffff;
    border: 0.5px solid #878787;
}

.address_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background: #ffffff;
    border: 0.5px solid #878787;
    margin-bottom: 10px;
}

.inside_address {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 10px !important;
}

.address_first {
    font-family: 'Poppins';
    width: 8%;
    font-style: normal;
    font-weight: 500;
    color: #878787;
    font-size: var(--desktop-text);
    
    background: #D9D9D9;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.address_middle {
    display: flex;
    flex-direction: column;
}

.address_middle>span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-text);
}

.address_data_buttons {
    display: flex;
    align-items: center;
    width: 100%;
}

.address_data_buttons>button {
    width: 50%;
    color: #FF7253;
    font-family: "Poppins";
    font-weight: 400;
    background-color: #ffffff;
    text-transform: uppercase;
    padding: 5px;
    border: 0.5px solid #878787;
    border-left: none;
    border-bottom: none;
}

@media screen and (max-width: 576px){

    .addAddress{
        font-size: 14px !important;
    }

    .editAddress{
        font-size: 14px !important;
    }

    .removeAddress{
        font-size: 14px !important;
    }

    .address_type>span{
        font-size: var(--mobile-text);
    }
    .inputLabels{
        font-size: var(--mobile-text);
    }
    .address_first{
        font-size: var(--mobile-text) !important;
    }
    .address_middle>span{
        font-size: var(--mobile-text) !important;
    }
    .address_mobile{
        margin-top: 0px !important;
        font-size: var(--mobile-text) !important;
    }
}

@media screen and (max-width: 992px){
    .address_type>span{
        font-size: var(--tablet-text);
    }
    .inputLabels{
        font-size: var(--tablet-text);
    }
    .address_first{
        font-size: var(--tablet-text);
    }
    .address_middle>span{
        font-size: var(--tablet-text);
    }
}