.priceFilterButton{
    border: 0.5px solid #878787;
    background: transparent;
    width: inherit;
    color: black;
}

.priceFilterSlider{
    width: 92.5%;
    margin-left: auto;
    margin-right: auto;
}

.MuiSlider-rail, .MuiSlider-track, .MuiSlider-thumbColorPrimary{
    color: #ff7703!important;
}

.MuiSlider-thumbColorPrimary:hover, .Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px #ff790345!important;
}