.notificationContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.notificationCard{
    border: 1px solid #878787;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin:25px;
}
.notificationCard:hover{
    cursor:pointer;
}

.current_orders_orderID, .truncateEllipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notifactionMessage{
    color: #FF7253;
    font-weight: 400;
    font-size: var(--desktop-text);
    padding: 5px;
    margin-left:16px;
}
.notificationSubCard .left {
    display: flex;
    
}

.notificationSubCard .left .content .up h1{
    color: #2E2E2E;
    font-weight: 500;
    font-size: 20px;
}
.notificationSubCard .left .content .up h2{
    color: #878787;
    font-weight: 400;
    font-size: var(--desktop-text);
}
.notificationSubCard .left .content .downn{
    color: #8A5B40;
    font-weight: 600;
    font-size: 20px;
}
.notificationSubCard .left .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    
}
.subCardImage{
    height: 140px;
    width: 130px;
    margin:5px;
    margin-right: 10px;
}
.notificationSubCard{
    display: flex;
    padding: 10px;
}
@media only screen and (max-width: 576px){
    .notifactionMessage{
        font-size: var(--mobile-text);
    }
    .notificationSubCard .left .content .up h2{
        font-size: var(--mobile-text);
    }
}

@media only screen and (max-width: 992px){
    .notifactionMessage{
        font-size: var(--tablet-text);
    }
    .notificationSubCard .left .content .up h2{
        font-size: var(--tablet-text);
    }
}