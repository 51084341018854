.related_products_products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.related_products_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.individual_product_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

}

.individual_product_name:hover{
  cursor: pointer;
}

.more_items_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--desktop-subheading);
  
  color: #8a5b40;
  position: relative;
}

.more_items_heading {
  display: none;
}

.more_items_heading::before {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 40%;
  height: 1px;
  top: 15px;
  left: -65px;
}

.more_items_heading::after {
  content: "";
  position: absolute;
  background-color: #000000;
  width: 40%;
  height: 1px;
  top: 15px;
  right: -65px;
}

.related_products_heading {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 700;
  font-size: var(--desktop-heading);
  color: #000000;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.related_individual_products {
  margin-bottom: 20px;
}

.divInside {
  width: 25%;
}

.widthCompo {
  width: 100% !important;
}

.individual_product_first_line {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  flex-wrap: inherit !important;
  width: 100% !important;
  color: #000000;
}

.RelatedProductsContainer{
  margin-left: 15px;
  margin-right: 15px;
}

.individual_product_company {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  ;
  letter-spacing: 0em;
  text-align: left;
  color: #878787;
  text-transform: capitalize;
}

.individual_product_company:hover{
  cursor: pointer;
}

.individual_product_price span:nth-child(1) {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
}

.individual_product_price span:nth-child(2) {
  font-family: Poppins;
  font-size: var(--desktop-text);
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;  
}

.individual_product_price span:nth-child(3) {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  ;
  letter-spacing: 0em;
  text-align: center;  
}

.related_products_showmore {
  margin: 20px 0;
}

.related_show_more_button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--desktop-text);
  
  color: black;
  border: 1px solid #FF7703;
  box-sizing: border-box;
  padding: 5px 40px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: transparent;
  transition: 0.1s;
}

.related_show_more_button:hover {
  background-color: transparent;
  box-shadow: none !important;
  color: black;
}

.mobileViewOptions {
  display: none;
  border: 0.5px solid #D9D9D9;
  /* padding: 5px; */
  margin-bottom: 10px;
}

.mobileViewOptions>button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: var(--desktop-text);
  color: #000000;
  
  width: 50%;
  padding: 10px;
  border-radius: 0px;
  border-right: 0.5px solid #D9D9D9;
}

@media screen and (max-width: 768px) {
  .related_products_products {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .divInside {
    width: 50%;
  }

  .individual_product_price span:nth-child(1){
    font-size: 14px;
  }

  .individual_product_price span:nth-child(2){
    font-size: var(--mobile-text);
  }

  .divInside>.related_individual_products>.product_img_block>img {
    width: 165.03px;
    height: 200.32px;
  }

  .relevance {
    width: 30% !important;
  }

  .tablet_mobile_heart {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 30px;
    height: 50px;
    width: 50px;
  }

  .related_individual_products {
    position: relative;
  }

  .tablet_mobile_heart>svg {
    height: 35px;
    width: 80px;
    stroke: #878787;
  }

  .lower_cont__heading {
    display: none;
  }

  .mobileViewOptions {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

@media screen and (max-width: 450px) {
  .divInside {
    width: 50%;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    width: 165.03px;
    height: 200.32px;
  }

  .individual_product_price span:nth-child(1){
    font-size: 14px;
  }
  
  .individual_product_price span:nth-child(2){
    font-size: var(--mobile-text);
  }

  .relevance {
    width: 30% !important;
  }

  .tablet_mobile_heart {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 45px;
    height: 50px;
    width: 50px;
  }

  .related_individual_products {
    position: relative;
  }

  .tablet_mobile_heart>svg {
    height: 35px;
    width: 80px;
    stroke: #878787;
  }

  .lower_cont__heading {
    display: none;
  }

  .mobileViewOptions {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

@media screen and (min-width:1030px) and (max-width:1230px) {
  .divInside {
    width: 33% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 240px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 340px !important;
  }
}

@media screen and (min-width:1235px) and (max-width:1445px) {
  /* .divInside {
    width: 25% !important;
  } */

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 220px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 340px !important;
  }
}

@media screen and (min-width:1450px) and (max-width:1650px) {
  /* .divInside {
    width: 30% !important;
  } */

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 260px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 340px !important;
  }
}

@media screen and (min-width:1655px) and (max-width:1818px) {
  /* .divInside {
    width: 30% !important;
  } */

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 280px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 340px !important;
  }
}

@media screen and (min-width:1820px) and (max-width:2150px) {
  .divInside {
    width: 20% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 260px !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 340px !important;
  }
}

@media screen and (min-width:2155px) and (max-width:2480px) {
  .divInside {
    width: 20% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 300px !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 420px !important;
  }
}

@media screen and (min-width:2485px) and (max-width:2740px) {
  .divInside {
    width: 20% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 330px !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 440px !important;
  }
}

@media screen and (min-width:2745px) {
  .divInside {
    width: 20% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 350px !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 460px !important;
  }
}

@media screen and (min-width: 765px) and (max-width: 900px) {
  .divInside {
    width: 33% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 176px !important;
    /* height: 272px !important; */
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 272px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .individual_product_price span:nth-child(1){
    font-size: 18px;
  }

  .individual_product_price span:nth-child(2){
    font-size: var(--tablet-text);
  }

}

@media screen and (min-width: 630px) and (max-width: 760px) {
  .divInside {
    width: 33% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 170px !important;
    /* height: 272px !important; */
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 272px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .individual_product_price span:nth-child(1){
    font-size: 18px;
  }

  .individual_product_price span:nth-child(2){
    font-size: var(--tablet-text);
  }
}

@media screen and (min-width: 500px) and (max-width: 625px) {
  .divInside {
    width: 50%;
  }

  .listContainer {
    width: 100% !important;
  }

  .individual_product_heart {
    display: none;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    width: 200px;
    height: 200.32px;
  }

  .relevance {
    width: 30% !important;
  }

  .tablet_mobile_heart {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 30px;
    height: 50px;
    width: 50px;
  }

  .related_individual_products {
    position: relative;
  }

  .tablet_mobile_heart>svg {
    height: 35px;
    width: 80px;
    stroke: #878787;
  }

  .lower_cont__heading {
    display: none;
  }

  .mobileViewOptions {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .filters {
    display: none;
  }

  .individual_product_price span:nth-child(1){
    font-size: 14px;
  }

  .individual_product_price span:nth-child(2){
    font-size: var(--mobile-text);
  }
}

@media screen and (min-width: 901px) and (max-width: 1025px) {
  .divInside {
    width: 33% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img,
  .product_img_block {
    width: 200px !important;
  }

  .relevance {
    width: 30% !important;
  }

  .divInside>.related_individual_products>.product_img_block>img {
    height: 340px !important;
  }

  .tablet_mobile_heart {
    display: none;
  }

  .individual_product_price span:nth-child(1){
    font-size: 18px;
  }

  .individual_product_price span:nth-child(2){
    font-size: var(--tablet-text);
  }

  /* .individual_product_heart{
    display: none;
  }
  
  .tablet_mobile_heart{
    display: block;
    position: absolute;
    top: 0px;
    right: 30px;
  }

  .related_individual_products{
    position: relative;
  } */
}