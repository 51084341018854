.popupBackPanel{
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1060;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000037;
    backdrop-filter: blur(8px);
}

.popupFrontPanel{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: #ffffff;
    height: 70%;
    width: 60%;
    position: relative;
    z-index: 1065;
}

.loginPopupCloseButton{
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    cursor: pointer;
}

.popupImage{
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.formDiv{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal.show.lrp-modal{
    z-index: 1075;
}

.modal-backdrop.show{
    z-index: 100;
}

.loginForm{
    margin-left: 50px;
    margin-right: 50px;
}

.loginPopupHeading{
    text-align: center;
    font-family: 'Trirong';
    font-style: normal;
    font-weight: 600;
    font-size: var(--desktop-heading);
}

.registerPopupHeading{
    text-align: center;
    font-family: 'Trirong';
    font-style: normal;
    font-weight: 600;
    font-size: var(--desktop-heading);
    margin-bottom: 30px;
}

.loginPopupInputText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: var(--desktop-text);
    color: #878787;
    margin: 0;
}

.popupShowPasswordButton{
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: none;
    width: 25px;
    height: 25px;
}

.loginPopupInputBox{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #878787;
    margin-bottom: 20px;
    width: 100%;
}
.registerPopupInputBox{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #878787;
    margin-bottom: 0px;
    width: 100%;
}

.loginPopupPasswordBox{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #878787;
    width: 100%;
}

.loginPopupInputBox:focus-within, .loginPopupPasswordBox:focus-within, .registerPopupRegisterButton:focus-within, .loginPopupPasswordBox:focus-within, .registerPopupInputBox:focus-visible{
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.loginPopupForgotPasswordButton{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: var(--desktop-subtext);
    color: #FF7253;
    border: none;
    background-color: transparent;
}

.loginPopupForgotPasswordButton:hover{
    text-decoration: underline;
}

.loginPopupButton{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.loginPopupLoginButton{
    border: none;
    background: #FF7703;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginPopupRegisterButton{
    border: 1px solid #FF7703;
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}

.registerPopupRegisterButton{
    border: 1px solid #FF7703;
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
}

.OtpInput > input {
    width: 2.5em !important;
    height: 2.5em !important;
}

.OtpInput{
    margin-top: 10px;
}

.OtpInput >input:focus{
    border: 2px solid #FF7703 !important;
    outline: none !important;
}

@media screen and (max-width: 992px) {
    .popupFrontPanel{
        width: 98%;
        height: auto;
    }
    .loginPopupHeading{
        font-size: var(--tablet-heading);
    }
    .registerPopupHeading{
        font-size: var(--tablet-heading);
    }
    .loginPopupInputText{
        font-size: var(--tablet-text);
    }
    .loginPopupForgotPasswordButton{
        font-size: var(--tablet-text);
    }
}

@media screen and (max-width: 1200px) {
    .LoginTabBox > div > div > div > button{
        font-size: var(--tablet-subtext) !important;
    }
    .OtpInput > input {
        width: 1.6em !important;
        height: 1.6em !important;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .LoginTabBox > div > div > div > button{
        font-size: var(--tablet-subtext) !important;
    }
    .OtpInput > input {
        width: 2em !important;
        height: 2em !important;
    }
}

@media screen and (max-width: 780px) {
    .popupFrontPanel{
        width: 85%;
    }
    .OtpInput > input {
        width: 1.3em !important;
        height: 1.3em !important;
    }
    .loginPopupHeading{
        font-size: var(--tablet-heading);
    }
    .registerPopupHeading{
        font-size: var(--tablet-heading);
    }
    .loginPopupInputText{
        font-size: var(--tablet-text);
    }
    .loginPopupForgotPasswordButton{
        font-size: var(--tablet-text);
    }
}

@media screen and (min-width: 577px) and (max-width:755px){
    .LoginTabBox > div > div > div > button{
        font-size: var(--tablet-subtext) !important;
    }
    .OtpInput > input {
        width: 1.0em !important;
        height: 1.0em !important;
    }
    .popupFrontPanel{
        width: 95%;
        height: 60vh;
        min-height: min-content;
    }
    .loginPopupHeading{
        font-size: var(--tablet-heading);
    }
    .registerPopupHeading{
        font-size: var(--tablet-heading);
    }
    .loginPopupInputText{
        font-size: var(--tablet-text);
    }
    .loginPopupForgotPasswordButton{
        font-size: var(--tablet-text);
    }
}

@media screen and (max-width: 576px) {
    .popupFrontPanel{
        width: 85%;
        height: 55vh;
    }

    .popupImage{
        display: none;
    }

    .formDiv{
        width: 90%;
    }
    .LoginTabBox > div > div > div > button{
        font-size: var(--tablet-subtext) !important;
    }
    .OtpInput > input {
        width: 1.6em !important;
        height: 1.6em !important;
    }
    .loginPopupHeading{
        font-size: var(--mobile-heading);
    }
    .registerPopupHeading{
        font-size: var(--mobile-heading);
    }
    .loginPopupInputText{
        font-size: var(--mobile-text);
    }
    .loginPopupForgotPasswordButton{
        font-size: var(--mobile-text);
    }
}
