.NewHireScrol::-webkit-scrollbar {
  display: none;
}

.NewHireScrol::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.imgg {
  height: 100%;
  width: 100%;
}

#rccCon {
  height: 73.5% !important;
}

.css-1m9128y {
  width: 100%;
  margin-top: -37% !important;
  margin-left: 3% !important;
  /* text-align: center; */
  position: absolute !important;
  z-index: 1 !important;
  /* margin-bottom: 122px; */
  text-align: start !important;
}

.css-aq8pb7-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 14px;
}

@media (max-width: 575px) {
  .css-aq8pb7-MuiSvgIcon-root {
    font-size: 11px !important;
  }
}

.rccImg {
  border-radius: 20px;
  height: 160px;
  cursor: pointer;
}

.hireImg {
  height: 160px;
  cursor: pointer;
}

@media (max-width: 575px) {
  .rccImg,
  .hireImg {
    height: 50px;
    cursor: pointer !important;
  }

  .rccImg {
    border-radius: 8px;
    cursor: pointer;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .rccImg,
  .hireImg {
    height: 110px;
    cursor: pointer !important;
  }

  .rccImg {
    border-radius: 8px;
    cursor: pointer;
  }
}

.css-ohwg9z {
  overflow: scroll;
  height: 634px;
}

@media (max-width: 575px) {
  .css-ohwg9z {
    overflow: scroll;
    height: 162px !important;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .css-ohwg9z {
    overflow: scroll;
    height: 340px !important;
  }
}

/* .splide__list{
    width: 85% !important;
  } */
#splide02-list {
  width: 85% !important;
}

.smallImg {
  border-radius: 14px !important;
}

/*Shimmer css*/
.shimmer-imagee {
  width: 31vw;
  height: 160px;
  background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
  background-size: 800px 100%;
  animation: shimmer-animation 1.5s infinite;
}
.loadingg-shimmer {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 10px;
    /* margin-bottom: 2em; */
  }
  .comp3-loading-shimmer {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 2em;
  }
  .comp3-shimmer-image {
    width: 100vw;
    height: 70vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }

@media (max-width: 576px) {
  .smallImg {
    border-radius: 7px !important;
  }
  .nenewHpMobileServicesParent .splide__list {
    justify-content: center !important;
  }
  .shimmer-imagee {
    height: 50px !important;
    width: 28vw;
  }
  .loadingg-shimmer {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 6px;
    /* margin-bottom: 2em; */
  }
  .comp3-shimmer-image {
    width: 98vw;
    height: 20vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
}


@media (min-width: 576px) and (max-width: 992px) {
  .smallImg {
    border-radius: 9px !important;
  }
  .shimmer-imagee {
    height: 100px !important;
    width: 29vw;
  }
  .comp3-shimmer-image {
    width: 98vw;
    height: 30vh;
    background: linear-gradient(to right, #ddd 0%, #f2f2f2 50%, #ddd 100%);
    background-size: 800px 100%;
    animation: shimmer-animation 1.5s infinite;
  }
}
